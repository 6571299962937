import React, { useEffect, useState } from "react";
import BarGraph from "./BarGraph";
import { DatePicker } from "antd";
import {
  caseAgentGraph,
  fetchagentCaseList,
} from "../../../../services/coreService";
import dayjs from "dayjs";
import Label from "../../../../components/Label/Label";
import AgentCaseTable from "./AgentCaseTable";
import Loader from "../../../../components/Loaders/Loader";
import { AGENT_ROLE_ADMIN, AGENT_ROLE_SUPERADMIN } from "../../../../constants/agentEnums";
import { useUser } from "../../../../context/UserContext";
import PermissionSegment from "../../../../components/PermissionLocks/PermissionSegment";
import PermissionFunction from "../../../../components/PermissionLocks/PermissionFunction";

function Reports({ organizationId }) {

  const { agent } = useUser();

  const currentMonthStart = dayjs().startOf("month");
  const currentMonthEnd = dayjs().endOf("month");

  const [startDate, setStartDate] = useState(currentMonthStart);
  const [endDate, setEndDate] = useState(currentMonthEnd);
  const [agentcaselist, setAgentCaseList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [caselist, setCaseList] = useState([]);

  const handleNewDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    }
  };

  useEffect(() => {
    PermissionFunction(agent?.role, [AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN], fetchagentcaseList, fetchAgentCases);
  }, [organizationId, startDate, endDate, agent?.role]);

  const fetchagentcaseList = async () => {
    const payload = {
      startDate: dayjs(startDate).toISOString(),
      endDate: dayjs(endDate).toISOString(),
    };
    setLoading(true);
    try {
      const res = await fetchagentCaseList(organizationId, payload);
      if (res?.status === "Success") {
        const { results } = res.data;
        setAgentCaseList(results);
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch case list:", error);
    }
  };

  const fetchAgentCases = async () => {
    const payload = {
      startDate: dayjs(startDate).toISOString(),
      endDate: dayjs(endDate).toISOString(),
    };
    try {
      const res = await caseAgentGraph(organizationId, payload);
      if (res?.status === "Success") {
        const { results } = res.data;
        setCaseList(results);
      }
    } catch (error) {
      console.error("Failed to fetch case list:", error);
    }
  };

  return (
    <PermissionSegment
    shouldHaveRoles={[AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN]}
    hasRole={agent?.role}
  >
    <div className=" overflow-y-scroll h-[90vh]">
      <div className="m-[30px]">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            justifyContent: "end",
          }}
        >
          <div>
            <div className="flex justify-end relative w-full gap-2">
              <Label text="Date" className="self-center" />

              <DatePicker.RangePicker
                inputReadOnly={true}
                format={"MMM DD, YYYY"}
                value={[startDate, endDate]}
                onChange={handleNewDateChange}
                className="w-7/10 p-1.5 rounded border border-gray-300 text-sm"
                disabledDate={(current) => current.isAfter(dayjs(), "day")}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div>
              <span className="text-xl font-normal">
                Case Counts Months Wise
              </span>
            </div>
            <div className="flex items-end justify-end">
              <div className="w-full shadow-lg mt-9 px-6 py-5 overflow-auto">
                <p className="text-xl font-normal">Showing case count</p>
                <BarGraph caselist={caselist} />
              </div>
            </div>

            <div className="mt-10">
              <div>
                <span className="text-xl font-normal pb-8">
                  Case Counts Agent Wise
                </span>
              </div>

              {agentcaselist.length > 0 ? (
                <AgentCaseTable agentcaselist={agentcaselist} />
              ) : (
                <div className="flex flex-row justify-center items-center h-full mt-[150px] mb-[100px]">
                  <p className="text-[20px] text-black font-semibold">
                    No data
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
    </PermissionSegment>
  );
}

export default Reports;
