import React, { useState } from "react";
import ComplaintType from "./ComplaintType.js";
import Loader from "../../../../components/Loaders/Loader.jsx";
import Icon from "../../../../components/Icon/Icon.jsx";
import Label from "../../../../components/Label/Label.jsx";
import { Divider } from "../../../../components/Divider/Divider.js";
import { calculateWordCount } from "../../../../utils/countWordsLeft.js";
import {
  APOLOGYLETTERGUIDANCE_MAX_LIMIT,
  COMPLAINTCLASSIFYINGCRITERIA_MAX_LIMIT,
  GLOBAL_TEXT_MAX_LIMIT,
  IDENTITY_CATEGORIES_MAX_LIMIT,
  IDENTITY_SUBCLAIM_MAX_LIMIT,
  MERGE_SUBCLAIM_MAX_LIMIT,
  REVIEW_SUBCLAIM_MAX_LIMIT,
} from "../../../../constants/playBookInputLimit.js";
import { displayComplaintName } from "../../../../utils/displayComplaintName.js";
import {
  AGENT_ROLE_SUPERADMIN,
  AGENT_ROLE_ADMIN,
} from "../../../../constants/agentEnums.js";
import trash from "../../../../Images/trashicon.svg";
import disabledtrash from "../../../../Images/disabledtrashicon.svg";
import { useUser } from "../../../../context/UserContext.js";
import PermissionSwitch from "../../../../components/PermissionLocks/PermissionSwitch";
import { useOrganization } from "../../../../context/OrganizationContext.js";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { truncate } from "lodash";
import { CLONE_PLAYBOOK_TEXT } from "../../../../constants/playbookComplaintsTypeConstants.js";
import InputForm from "../../../../components/InputForm/InputForm.js";
import ActionModal from "../../../../components/ActionModal/ActionModal.js";
import { PLAYBOOK_ACTION_TEXT } from "../../../../constants/casesConstants.js";
import { showNotification } from "../../../../components/Toastify/Toast.js";

export default function PlaybookDetails({
  selectedPlaybook,
  setSelectedPlaybook,
  initialPlaybook,
  selectedPlaybookStatus,
  handlePlaybookStatus,
  playbookStatusLoader,
  editApologyPlaybookLoading,
  isEditableApology,
  toggleEditableApology,
  handleOpenAddComplaintModal,
  handleOpenEditModal,
  handleUpdatePlaybook,
  editCompaintPlaybookLoading,
  toggleEditableComplaint,
  isEditableComplaint,
  expandedComplaints,
  playbookComplaintsData,
  toggleComplaintDetails,
  handleRemovingPlaybook,
  handleClickCopyClone,
  isLoading,
  setPlaybookTitle,
  playbooktitle,
  editplaybooktitleLoading,
  islockplaybookloader,
  handleLockPlaybook,
  removeplaybookloader,
  openDeleteModal,
  handleDeletePlaybookModalOpen,
  handleDeletePlaybookModalClose,
  downloadplaybookUrl,
  isEditableIdentifySubclaim,
  isEditablemergeSubclaim,
  isEditablereviewSubclaim,
  isEditableIdentifyCategories,
  toggleEditableIdentifySubclaim,
  toggleEditableIdentifyCategories,
  toggleEditableMergeSubclaims,
  toggleEditableReviewSubclaims,
  editIdentifySubclaimPlaybookLoading,
  editMergeSubclaimPlaybookLoading,
  editReviewSubclaimPlaybookLoading,
  editIdentifyCategoriesPlaybookLoading,
  editglobalTextLoading,
  toggleEditableGlobalText,
  isEditableglobaltext,

}) {
  const { agent } = useUser();
  const [title, setChangeTitle] = useState(false);
  const { organization } = useOrganization();
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };


  const apologyLetterGuidanceWordCount = calculateWordCount(
    selectedPlaybook?.apologyLetterGuidance || "",
    APOLOGYLETTERGUIDANCE_MAX_LIMIT
  );
  const complaintClassifyingCriteriaWordCount = calculateWordCount(
    selectedPlaybook?.complaintClassifyingCriteria || "",
    COMPLAINTCLASSIFYINGCRITERIA_MAX_LIMIT
  );
  const identifySubclaimWordCount = calculateWordCount(
    selectedPlaybook?.identifySubclaims || "",
    IDENTITY_SUBCLAIM_MAX_LIMIT
  );
  const mergeSubclaimWordCount = calculateWordCount(
    selectedPlaybook?.mergeSubclaims || "",
    MERGE_SUBCLAIM_MAX_LIMIT
  );
  const identifyCategoriesWordCount = calculateWordCount(
    selectedPlaybook?.identifyCategories || "",
    IDENTITY_CATEGORIES_MAX_LIMIT
  );
  const reviewSubclaimWordCount = calculateWordCount(
    selectedPlaybook?.reviewSubclaims || "",
    REVIEW_SUBCLAIM_MAX_LIMIT
  );

  const globalTextWordCount = calculateWordCount(
    selectedPlaybook?.globalText || "",
    GLOBAL_TEXT_MAX_LIMIT
  );


  const removePlaybook = (playbookId) => {
    handleRemovingPlaybook(playbookId);

  };
  const handleChangeTitle = () => {
    setChangeTitle(true);
  };

  const handleCancelTitle = () => {
    setPlaybookTitle(selectedPlaybook?.name || ""); //.setPLyb
    setChangeTitle(false);   //toggle 
  };

  const handleSaveTitle = () => {

    if (!playbooktitle?.trim()) {
      showNotification("Playbook Name cannot be empty", "error");
      return;
    }
    handleUpdatePlaybook("playbooktitlechange");
    setChangeTitle(false);
  };

  const createdName = (name) => {
    if (!name) {
      return
    }
    return `${name.firstName} ${name.lastName}`;
  }


  const handleInputCancel = (id, playbookId) => {
    setSelectedPlaybook((prev) => ({
      ...prev,
      [id]: initialPlaybook[id],
    }));

    if (id === "apologyLetterGuidance") toggleEditableApology(playbookId);
    else if (id === "complaintClassifyingCriteria") toggleEditableComplaint(playbookId);
    else if (id === "identifySubclaims") toggleEditableIdentifySubclaim(playbookId);
    else if (id === "mergeSubclaims") toggleEditableMergeSubclaims(playbookId);
    else if (id === "identifyCategories") toggleEditableIdentifyCategories(playbookId);
    else if (id === "reviewSubclaims") toggleEditableReviewSubclaims(playbookId);
    else if (id === "globalText") toggleEditableGlobalText(playbookId);

  }


  return (
    <>
      <div className="flex w-[100%-27.22%] h-[80px] pl-5 pt-5 pb-1 px-8 items-center gap-2.5 text-[#000] justify-between">
        <div className="flex flex-col">
          {title ? (
            <div className="flex flex-row gap-2">
              <input
                value={playbooktitle}
                onChange={(e) => setPlaybookTitle(e.target.value)}
                className="w-full px-2 py-1 text-gray-700 placeholder-gray-400 transition duration-200 ease-in-out border rounded-lg focus:outline-none focus:ring-2 mb-1"
                data-testid="title-input"
              />
              <span
                onClick={() => handleSaveTitle()}
                className="self-center cursor-pointer text-[14px] text-blue-500"
              >
                Save
              </span>
              <span
                onClick={() => handleCancelTitle()}
                className="self-center cursor-pointer text-[14px] text-blue-500"
                data-testid="cancel-button"
              >
                Cancel
              </span>
            </div>
          ) : (
            <div className="flex flex-row gap-2">
              <span className="text-[20px]" data-testid="playbookName">
                {truncate(selectedPlaybook?.name, { length: 60 })}
              </span>

              {editplaybooktitleLoading ? (
                <Loader />
              ) : (
                <div
                  onClick={() => handleChangeTitle()}
                  className="self-center cursor-pointer"
                  data-testid="change-title-div"
                >
                  <Icon type="EditIcon" className="h-3 w-3 self-center ml-1" />
                </div>
              )}
              <div
                className="self-center cursor-pointer"
                onClick={() => downloadplaybookUrl(selectedPlaybook?._id)}
              >
                <Icon type="DownloadIcon" className="h-4 w-4 ml-1" />
              </div>
            </div>
          )}
          <div className="flex flex-row gap-2">
            {selectedPlaybook?.createdBy &&
              <p className="text-[14px]">Creator {createdName(selectedPlaybook?.createdBy)}</p>
            }
            <p className="font-inter text-[12px] text-[#727272] self-center">
              {formatDate(selectedPlaybook?.updatedAt)}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-2">
          {isLoading ? (
            <Loader />
          ) : (
            <div
              data-tooltip-id="copy-clone-playbook"
              className="self-center cursor-pointer"
              onClick={() =>
                handleClickCopyClone(organization?._id, selectedPlaybook?._id)
              }
            >
              <Icon type="DocumentCopyIcon" />
            </div>
          )}
          <ReactTooltip
            id="copy-clone-playbook"
            place="bottom"
            content={CLONE_PLAYBOOK_TEXT}
          />
          <PermissionSwitch
            shouldHaveRoles={[AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN]}
            hasRole={agent?.role}
            yes={
              <>
                <button
                  onClick={() => handleDeletePlaybookModalOpen(selectedPlaybook?._id)}
                  className="p-1.5 text-white bg-transparent border-none cursor-pointer"
                >
                  <img src={trash} alt="Delete" />
                </button>
                <p
                  className={`flex items-center justify-center gap-2 px-2 py-1 rounded-[36px] opacity-75 font-inter text-[12px] font-medium leading-[140%] ${selectedPlaybookStatus === "Active"
                    ? "bg-[#E8F9E6] text-[#4CAF50]"
                    : "bg-[#E4E4E4] text-[#8A8A8A]"
                    }"}`}
                >
                  {selectedPlaybook?.isActive ? "Active" : "Inactive"}
                </p>
                {openDeleteModal === selectedPlaybook?._id && (
                  <ActionModal
                    title={PLAYBOOK_ACTION_TEXT}
                    onClose={handleDeletePlaybookModalClose}
                    isloader={removeplaybookloader}
                    onconfirm={() => {
                      removePlaybook(selectedPlaybook?._id);
                    }}
                  />
                )}
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    handlePlaybookStatus(
                      selectedPlaybook?._id,
                      selectedPlaybook?.isActive ? "deactivate" : "activate"
                    )
                  }
                >
                  {playbookStatusLoader ? (
                    <Loader />
                  ) : selectedPlaybook?.isActive ? (
                    <Icon
                      type="ToggleOnIcon"
                      className="text-[#4CAF50] h-5 w-6"
                    />
                  ) : (
                    <Icon type="ToggleOffIcon" className="h-5 w-6" />
                  )}
                </div>
                <div className="flex gap-1 justify-center">
                  <p> Lock</p>
                  <div className="self-center cursor-pointer"
                    onClick={() =>
                      handleLockPlaybook(
                        selectedPlaybook?._id,
                        selectedPlaybook?.isLocked ? "unlock" : "lock",
                      )
                    }
                  >
                    {islockplaybookloader ? (
                      <Loader />
                    ) : selectedPlaybook?.isLocked ? (
                      <Icon
                        type="ToggleOnIcon"
                        className="text-[#4CAF50] h-5 w-6"
                      />
                    ) : (
                      <Icon type="ToggleOffIcon" className="h-5 w-6" />
                    )}
                  </div>
                </div>
              </>
            }
            no={
              <>
                <button
                  className="p-1.5 text-white bg-transparent border-none"
                  disabled={true}
                >
                  <img src={disabledtrash} alt="Delete" />
                </button>
                <p
                  className={`flex items-center justify-center gap-2 px-2 py-1 rounded-[36px] opacity-75 font-inter text-[12px] font-medium leading-[140%] ${selectedPlaybookStatus === "Active"
                    ? "bg-[#E8F9E6] text-[#4CAF50]"
                    : "bg-[#E4E4E4] text-[#8A8A8A]"
                    }"}`}
                >
                  {selectedPlaybook?.isActive ? "Active" : "Inactive"}
                </p>
                <div className="cursor-pointer" >
                  {playbookStatusLoader ? (
                    <Loader />
                  ) : selectedPlaybook?.isActive ? (
                    <Icon
                      type="ToggleOnIcon"
                      className="text-[#4CAF50] h-5 w-6"
                    />
                  ) : (
                    <Icon type="ToggleOffIcon" className="h-5 w-6" />
                  )}
                </div>
                <div className="flex gap-1 justify-center">
                  <p> Lock</p>
                  <div className="self-center">
                    {islockplaybookloader ? (
                      <Loader />
                    ) : selectedPlaybook?.isLocked ? (
                      <Icon
                        type="ToggleOnIcon"
                        className="text-[#4CAF50] h-5 w-6"
                      />
                    ) : (
                      <Icon type="ToggleOffIcon" className="h-5 w-6" />
                    )}
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      <Divider />

      <div
        style={{ height: "calc(100dvh - 148px)", overflowY: "auto" }}
        className="p-4"
      >
        {/* Apology and Complaint Classifying Criteria */}
        <div className="flex flex-col">
          <div className="mb-6">
            <Label
              className="font-inter text-[16px] font-medium text-[#727272] mb-2"
              htmlFor="apologyLetterGuidance"
              text="Apology Criteria"
            />
            {editApologyPlaybookLoading ? (
              <button className="ml-2">
                <Loader />{" "}
              </button>
            ) : (
              <button
                type="button"
                className="ml-2 text-blue-500"

              >
                {!isEditableApology[selectedPlaybook?._id] ? (
                  <Icon type="EditIcon" className="h-3 w-3" onClick={() => toggleEditableApology(selectedPlaybook?._id)} />
                ) : (
                  <div className="flex gap-2">
                    <span
                      onClick={() =>
                        handleUpdatePlaybook("apologyLetterGuidance", selectedPlaybook?._id)
                      }
                      className="text-[14px]"
                      data-testid="save-button"
                    >
                      Save
                    </span>
                    <span className="text-[14px]" data-testid="cancel-button" onClick={() => handleInputCancel("apologyLetterGuidance", selectedPlaybook?._id)}>Cancel</span>
                  </div>
                )}
              </button>
            )}
            {isEditableApology[selectedPlaybook?._id] && (
              <p className="text-[14px] text-red-500 float-right">
                {apologyLetterGuidanceWordCount > 0
                  ? `${apologyLetterGuidanceWordCount} words left`
                  : `Word limit reached`}
              </p>
            )}
            <textarea
              id="apologyLetterGuidance"
              data-testid="apologyLetterGuidance"
              className="mt-2 w-full p-3 border border-[#E4E4E4] rounded-[4px] text-[14px] text-[#000] resize-none"
              placeholder="Enter Apology Criteria (Limit: 2000 words)"
              value={selectedPlaybook?.apologyLetterGuidance}
              rows={6}
              onChange={(e) => {
                const wordCount = calculateWordCount(
                  e.target.value,
                  APOLOGYLETTERGUIDANCE_MAX_LIMIT
                );
                if (wordCount >= 0) {
                  setSelectedPlaybook({
                    ...selectedPlaybook,
                    apologyLetterGuidance: e.target.value,
                  });
                }
              }}
              disabled={!isEditableApology[selectedPlaybook?._id]}
            />
          </div>

          <div className="mb-6">
            <Label
              className="font-inter text-[16px] font-medium text-[#727272] mb-2"
              htmlFor="complaintClassifyingCriteria"
              text="Complaint Classifying Criteria"
            />
            {editCompaintPlaybookLoading ? (
              <button className="ml-2">
                <Loader />{" "}
              </button>
            ) : (
              <button
                type="button"
                className="ml-2 text-blue-500"

              >
                {!isEditableComplaint[selectedPlaybook?._id] ? (
                  <Icon type="EditIcon" className="h-3 w-3" onClick={() => toggleEditableComplaint(selectedPlaybook?._id)} />
                ) : (
                  <div className="flex gap-2">
                    <span
                      onClick={() =>
                        handleUpdatePlaybook("complaintClassifyingCriteria", selectedPlaybook?._id)
                      }
                      className="text-[14px]"
                      data-testid="save-button"
                    >
                      Save
                    </span>
                    <span className="text-[14px]" data-testid="cancel-button" onClick={() => handleInputCancel("complaintClassifyingCriteria", selectedPlaybook?._id)}>Cancel</span>
                  </div>
                )}
              </button>
            )}
            {isEditableComplaint[selectedPlaybook?._id] && (
              <p className="text-[14px] text-red-500 float-right">
                {complaintClassifyingCriteriaWordCount > 0
                  ? `${complaintClassifyingCriteriaWordCount} words left`
                  : `Word limit reached`}
              </p>
            )}
            <textarea
              id="complaintClassifyingCriteria"
              data-testid="complaintClassifyingCriteria"
              className="mt-2 w-full p-3 border border-[#E4E4E4] rounded-[4px] text-[14px] text-[#000] resize-none"
              placeholder="Enter Complaint Classifying Criteria (Limit: 500 words)"
              value={selectedPlaybook?.complaintClassifyingCriteria}
              rows={6}
              onChange={(e) => {
                const wordCount = calculateWordCount(
                  e.target.value,
                  COMPLAINTCLASSIFYINGCRITERIA_MAX_LIMIT
                );
                if (wordCount >= 0) {
                  setSelectedPlaybook({
                    ...selectedPlaybook,
                    complaintClassifyingCriteria: e.target.value,
                  });
                }
              }}
              disabled={!isEditableComplaint[selectedPlaybook?._id]}
            />
          </div>

          <div className="mb-6">
            <Label
              className="font-inter text-[16px] font-medium text-[#727272] mb-2"
              htmlFor="identifySubclaims "
              text="Identify Subclaims "
            />
            {editIdentifySubclaimPlaybookLoading ? (
              <button className="ml-2">
                <Loader />{" "}
              </button>
            ) : (
              <button
                type="button"
                className="ml-2 text-blue-500"

              >
                {!isEditableIdentifySubclaim[selectedPlaybook?._id] ? (
                  <Icon type="EditIcon" className="h-3 w-3" onClick={() => toggleEditableIdentifySubclaim(selectedPlaybook?._id)} />
                ) : (
                  <div className="flex gap-2">
                    <span
                      onClick={() =>
                        handleUpdatePlaybook("identifySubclaims", selectedPlaybook?._id)
                      }
                      className="text-[14px]"
                      data-testid="save-button"
                    >
                      Save
                    </span>
                    <span className="text-[14px]" data-testid="cancel-button" onClick={() => handleInputCancel("identifySubclaims", selectedPlaybook?._id)}>Cancel</span>
                  </div>
                )}
              </button>
            )}
            {isEditableIdentifySubclaim[selectedPlaybook?._id] && (
              <p className="text-[14px] text-red-500 float-right">
                {identifySubclaimWordCount > 0
                  ? `${identifySubclaimWordCount} words left`
                  : `Word limit reached`}
              </p>
            )}
            <textarea
              id="identifySubclaims"
              data-testid="identifySubclaims"
              className="mt-2 w-full p-3 border border-[#E4E4E4] rounded-[4px] text-[14px] text-[#000] resize-none"
              placeholder="Enter Identify Subclaim (Limit: 2000 words)"
              value={selectedPlaybook?.identifySubclaims}
              rows={6}
              onChange={(e) => {
                const wordCount = calculateWordCount(e.target.value, IDENTITY_SUBCLAIM_MAX_LIMIT);
                if (wordCount >= 0) {
                  setSelectedPlaybook({
                    ...selectedPlaybook,
                    identifySubclaims: e.target.value,
                  });
                }
              }}
              disabled={!isEditableIdentifySubclaim[selectedPlaybook?._id]}
            />
          </div>

          <div className="mb-6">
            <Label
              className="font-inter text-[16px] font-medium text-[#727272] mb-2"
              htmlFor="mergeSubclaims "
              text="Merge Subclaims"
            />
            {editMergeSubclaimPlaybookLoading ? (
              <button className="ml-2">
                <Loader />{" "}
              </button>
            ) : (
              <button
                type="button"
                className="ml-2 text-blue-500"

              >
                {!isEditablemergeSubclaim[selectedPlaybook?._id] ? (
                  <Icon type="EditIcon" className="h-3 w-3" onClick={() => toggleEditableMergeSubclaims(selectedPlaybook?._id)} />
                ) : (
                  <div className="flex gap-2">
                    <span
                      onClick={() =>
                        handleUpdatePlaybook("mergeSubclaims", selectedPlaybook?._id)
                      }
                      className="text-[14px]"
                      data-testid="save-button"
                    >
                      Save
                    </span>
                    <span className="text-[14px]" data-testid="cancel-button" onClick={() => handleInputCancel("mergeSubclaims", selectedPlaybook?._id)}>Cancel</span>
                  </div>
                )}
              </button>
            )}
            {isEditablemergeSubclaim[selectedPlaybook?._id] && (
              <p className="text-[14px] text-red-500 float-right">
                {mergeSubclaimWordCount > 0
                  ? `${mergeSubclaimWordCount} words left`
                  : `Word limit reached`}
              </p>
            )}
            <textarea
              id="mergeSubclaims"
              data-testid="mergeSubclaims"
              className="mt-2 w-full p-3 border border-[#E4E4E4] rounded-[4px] text-[14px] text-[#000] resize-none"
              placeholder="Enter Merge Subclaim (Limit: 2000 words)"
              value={selectedPlaybook?.mergeSubclaims}
              rows={6}
              onChange={(e) => {
                const wordCount = calculateWordCount(
                  e.target.value,
                  MERGE_SUBCLAIM_MAX_LIMIT
                );
                if (wordCount >= 0) {
                  setSelectedPlaybook({
                    ...selectedPlaybook,
                    mergeSubclaims: e.target.value,
                  });
                }
              }}
              disabled={!isEditablemergeSubclaim[selectedPlaybook?._id]}
            />
          </div>

          <div className="mb-6">
            <Label
              className="font-inter text-[16px] font-medium text-[#727272] mb-2"
              htmlFor="identifyCategories "
              text="Identify Categories"
            />
            {editIdentifyCategoriesPlaybookLoading ? (
              <button className="ml-2">
                <Loader />{" "}
              </button>
            ) : (
              <button
                type="button"
                className="ml-2 text-blue-500"

              >
                {!isEditableIdentifyCategories[selectedPlaybook?._id] ? (
                  <Icon type="EditIcon" className="h-3 w-3" onClick={() => toggleEditableIdentifyCategories(selectedPlaybook?._id)} />
                ) : (
                  <div className="flex gap-2">
                    <span
                      onClick={() =>
                        handleUpdatePlaybook("identifyCategories", selectedPlaybook?._id)
                      }
                      className="text-[14px]"
                      data-testid="save-button"
                    >
                      Save
                    </span>
                    <span className="text-[14px]" data-testid="cancel-button" onClick={() => handleInputCancel("identifyCategories", selectedPlaybook?._id)}>Cancel</span>
                  </div>
                )}
              </button>
            )}
            {isEditableIdentifyCategories[selectedPlaybook?._id] && (
              <p className="text-[14px] text-red-500 float-right">
                {identifyCategoriesWordCount > 0
                  ? `${identifyCategoriesWordCount} words left`
                  : `Word limit reached`}
              </p>
            )}
            <textarea
              id="identifyCategories"
              data-testid="identifyCategories"
              className="mt-2 w-full p-3 border border-[#E4E4E4] rounded-[4px] text-[14px] text-[#000] resize-none"
              placeholder="Enter Identify Categories (Limit: 2000 words)"
              value={selectedPlaybook?.identifyCategories}
              rows={6}
              onChange={(e) => {
                const wordCount = calculateWordCount(
                  e.target.value,
                  IDENTITY_CATEGORIES_MAX_LIMIT
                );
                if (wordCount >= 0) {
                  setSelectedPlaybook({
                    ...selectedPlaybook,
                    identifyCategories: e.target.value,
                  });
                }
              }}
              disabled={!isEditableIdentifyCategories[selectedPlaybook?._id]}
            />
          </div>

          <div className="mb-6">
            <Label
              className="font-inter text-[16px] font-medium text-[#727272] mb-2"
              htmlFor="reviewSubclaims "
              text="Review Subclaims"
            />
            {editReviewSubclaimPlaybookLoading ? (
              <button className="ml-2">
                <Loader />{" "}
              </button>
            ) : (
              <button
                type="button"
                className="ml-2 text-blue-500"

              >
                {!isEditablereviewSubclaim[selectedPlaybook?._id] ? (
                  <Icon type="EditIcon" className="h-3 w-3" onClick={() => toggleEditableReviewSubclaims(selectedPlaybook?._id)} />
                ) : (
                  <div className="flex gap-2">
                    <span
                      onClick={() =>
                        handleUpdatePlaybook("reviewSubclaims", selectedPlaybook?._id)
                      }
                      className="text-[14px]"
                      data-testid="save-button"
                    >
                      Save
                    </span>
                    <span className="text-[14px]" data-testid="cancel-button" onClick={() => handleInputCancel("reviewSubclaims", selectedPlaybook?._id)} >Cancel</span>
                  </div>
                )}
              </button>
            )}
            {isEditablereviewSubclaim[selectedPlaybook?._id] && (
              <p className="text-[14px] text-red-500 float-right">
                {reviewSubclaimWordCount > 0
                  ? `${reviewSubclaimWordCount} words left`
                  : `Word limit reached`}
              </p>
            )}
            <textarea
              id="reviewSubclaims"
              data-testid="reviewSubclaims"
              className="mt-2 w-full p-3 border border-[#E4E4E4] rounded-[4px] text-[14px] text-[#000] resize-none"
              placeholder="Enter Review Subclaim (Limit: 2000 words)"
              value={selectedPlaybook?.reviewSubclaims}
              rows={6}
              onChange={(e) => {
                const wordCount = calculateWordCount(
                  e.target.value,
                  REVIEW_SUBCLAIM_MAX_LIMIT
                );
                if (wordCount >= 0) {
                  setSelectedPlaybook({
                    ...selectedPlaybook,
                    reviewSubclaims: e.target.value,
                  });
                }
              }}
              disabled={!isEditablereviewSubclaim[selectedPlaybook?._id]}
            />
          </div>




          <div className="mb-6">
            <Label
              className="font-inter text-[16px] font-medium text-[#727272] mb-2"
              htmlFor="globalText "
              text="Global Text"
            />
            {editglobalTextLoading ? (
              <button className="ml-2">
                <Loader />{" "}
              </button>
            ) : (
              <button
                type="button"
                className="ml-2 text-blue-500"

              >
                {!isEditableglobaltext[selectedPlaybook?._id] ? (
                  <Icon type="EditIcon" className="h-3 w-3" onClick={() => toggleEditableGlobalText(selectedPlaybook?._id)} />
                ) : (
                  <div className="flex gap-2">
                    <span
                      onClick={() =>
                        handleUpdatePlaybook("globalText", selectedPlaybook?._id)
                      }
                      className="text-[14px]"
                      data-testid="save-button"
                    >
                      Save
                    </span>
                    <span className="text-[14px]" data-testid="cancel-button" onClick={() => handleInputCancel("globalText", selectedPlaybook?._id)} >Cancel</span>
                  </div>
                )}
              </button>
            )}
            {isEditableglobaltext[selectedPlaybook?._id] && (
              <p className="text-[14px] text-red-500 float-right">
                {globalTextWordCount > 0
                  ? `${globalTextWordCount} words left`
                  : `Word limit reached`}
              </p>
            )}
            <textarea
              id="globalText"
              data-testid="globalText"
              className="mt-2 w-full p-3 border border-[#E4E4E4] rounded-[4px] text-[14px] text-[#000] resize-none"
              placeholder="Enter Global Text (Limit: 2500 words)"
              value={selectedPlaybook?.globalText}
              rows={6}
              onChange={(e) => {
                const wordCount = calculateWordCount(
                  e.target.value,
                  GLOBAL_TEXT_MAX_LIMIT
                );
                if (wordCount >= 0) {
                  setSelectedPlaybook({
                    ...selectedPlaybook,
                    globalText: e.target.value,
                  });
                }
              }}
              disabled={!isEditableglobaltext[selectedPlaybook?._id]}
            />
          </div>


        </div>

        {/* Complaint Types */}

        <div className="mb-10">
          <div className="flex items-center mb-4 space-x-2">
            <Label
              className="font-inter text-[16px] font-medium text-[#727272]"
              htmlFor={"complaintTypes"}
              text={"Complaint Types"}
            />
            <Icon
              className="cursor-pointer w-5 h-5"
              type="PlusIcon"
              onClick={handleOpenAddComplaintModal}
            />
          </div>
          <div
            className="flex flex-col gap-3 mt-2"
            data-testid="ExpandedContent"
          >
            {playbookComplaintsData?.map((complaint) => (
              <div key={complaint?._id} className="flex flex-col">
                <div
                  className={`flex items-center justify-between p-3 bg-[#F9F9F9] border border-[#E4E4E4] rounded-[4px] cursor-pointer ${expandedComplaints?.includes(complaint?._id)
                    ? "bg-[#F2F2F2]"
                    : ""
                    }`}
                  onClick={() => toggleComplaintDetails(complaint?._id)}
                >
                  <div className="flex items-center gap-3">
                    <span className="font-inter text-[14px] font-medium text-[#000]">
                      {displayComplaintName(complaint?.typeOfComplaint)}
                    </span>
                    <Icon
                      type="EditIcon"
                      onClick={() => handleOpenEditModal(complaint)}
                      className="h-3 w-3"
                    />
                  </div>

                  {expandedComplaints?.includes(complaint?._id) ? (
                    <Icon type="ArrowUpIcon" className="text-[#727272]" />
                  ) : (
                    <Icon type="ArrowDownIcon" className="text-[#727272]" />
                  )}
                </div>
                {expandedComplaints?.includes(complaint?._id) && (
                  <ComplaintType complaint={complaint} />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
