import React, { useState } from "react";
import InputForm from "../../../../../components/InputForm/InputForm";
import collapse from "../../../../../Images/Collapse.svg";
import { memberRole } from "../../../../../constants/casesConstants";
import SelectDropdown from "../../../../../components/DropDown/Dropdown";
import eye from "../../../../../Images/eye.svg";
import closeeye from "../../../../../Images/eyeclose.svg";
import { useUser } from "../../../../../context/UserContext";

function AddUpdateMember({
  role,
  member,
  setRole,
  isEditMode,
  handleChange,
  showPassword,
  handleShowPassword,
  errors,
}) {

  const { agent } = useUser();

  const handleFieldChange = (field, value) => {
    handleChange(field, value);

    if (field === "firstName" || field === "lastName") {
      const updatedContactName = `${field === "firstName" ? value : member.firstName || ""
        } ${field === "lastName" ? value : member.lastName || ""}`.trim();
      handleChange("contactName", updatedContactName);
    }
  };

  const showError = (keyName) => {
    return errors && errors[keyName] ? (
      <p className="text-red-500 text-[14px] pl-8 pt-2">{errors[keyName]}</p>
    ) : null;
  };

  const printLabel = (text) => {
    return (
      <>
        <span>{text}</span>
        <span className="text-red-500">*</span>
      </>
    );
  };

  return (
    <div data-testid="AddMember">
      <div>
        <div className="py-[5px]">
          <InputForm
            value={member.firstName || ""}
            handleChange={(e) => handleFieldChange("firstName", e.target.value)}
            label={isEditMode ? "First Name" : printLabel("First Name")}
            placeholder="Enter first name"
            isValid={true}
            id="input-firstName"
          />
          {showError("firstName")}
        </div>
        <div className="py-[5px]">
          <InputForm
            value={member.lastName || ""}
            handleChange={(e) => handleFieldChange("lastName", e.target.value)}
            label={printLabel("Last Name")}
            placeholder="Enter last name"
            isValid={true}
            id="input-lastName"
          />
          {showError("lastName")}
        </div>
        <div
          style={{ width: "100%", marginLeft: "30px", marginRight: "30px" }}
        ></div>
        <div className="py-[5px]">
          <InputForm
            value={member.contactName || ""}
            handleChange={(e) => handleChange("contactName", e.target.value)}
            label={printLabel("Contact Name")}
            placeholder="Enter Contact Name"
            isValid={true}
            id="input-contactName"
          />
          {showError("contactName")}
        </div>
        <div className="py-[5px]">
          <InputForm
            value={member.username || ""}
            handleChange={(e) => handleChange("username", e.target.value)}
            label={printLabel("Username")}
            placeholder="Enter Username"
            isValid={true}
            id="input-username"
          />
          {showError("username")}
        </div>
        <div className="py-[5px]">
          <InputForm
            value={member.contactEmail || ""}
            handleChange={(e) => handleChange("contactEmail", e.target.value)}
            label={printLabel("Contact Email")}
            placeholder="Enter Email"
            isValid={true}
            id="input-contactEmail"
          />
          {showError("contactEmail")}
        </div>
        <div className="px-8 py-[5px]" data-testid="select-role">
          <p className="block mb-2 text-sm font-medium text-gray-700">
            {printLabel("Role")}
          </p>
          <SelectDropdown
            options={isEditMode ? memberRole[agent?.role] : memberRole["admin"]}
            value={role}
            onChange={(val) => setRole(val)}
            collapseIcon={collapse}
            placeholder="Select an option..."
            data-testid="select-role"
          />
        </div>
        {showError("role")}
        <p className="block mt-2 text-sm font-medium text-gray-700 mx-8">
          {printLabel("Contact Phone")}
        </p>
        <div className="flex flex-row px-8 gap-4 items-baseline">
          <div
            style={{ width: "120px", display: "flex", alignItems: "center" }}
          >
            <div className="flex items-center border rounded-lg focus:outline-none focus:ring-1 h-10 mt-2">
              <span className="text-gray-700 pointer-events-none text-[18px] ml-1 mr-1">
                +
              </span>

              <input
                value={`${member.countryCode ? member.countryCode.replace("+", "") : ""
                  }`}
                onChange={(e) => handleChange("countryCode", e.target.value)}
                placeholder="Code"
                isValid={true}
                maxLength={4}
                className="w-[80%] py-1 text-gray-700 placeholder-gray-400 outline-none"
              />
            </div>
          </div>

          <InputForm
            value={member.contactPhone || ""}
            handleChange={(e) => handleChange("contactPhone", e.target.value)}
            placeholder="Enter Phone Number"
            isValid={true}
          />
        </div>
        {showError("countryCode")}
        {showError("contactPhone")}
        <>
          <div className="py-[5px]">
            <InputForm
              value={member.employeeId || ""}
              handleChange={(e) => handleChange("employeeId", e.target.value)}
              label={printLabel("Employee Id")}
              placeholder="Enter Employee Id"
              isValid={true}
            />
            {showError("employeeId")}
          </div>
          {!isEditMode && (
            <div className="py-[5px]">
              <div style={{ position: "relative" }}>
                <InputForm
                  value={member.password || ""}
                  handleChange={(e) => handleChange("password", e.target.value)}
                  label={printLabel("Password")}
                  placeholder="Enter Password"
                  isValid={true}
                  type={showPassword ? "text" : "password"}
                />
                <div
                  style={{
                    position: "absolute",
                    right: "40px",
                    marginTop: "13px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShowPassword()}
                >
                  <img
                    data-testid="toggle-password-visibility"
                    src={showPassword ? eye : closeeye}
                    alt="toggle visibility"
                  />
                </div>
              </div>
              {showError("password")}
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default AddUpdateMember;
