import React, { useState } from "react";
import edit from "../../../../../Images/editicon.svg";
import trash from "../../../../../Images/trashicon.svg";
import disablededit from "../../../../../Images/disabledediticon.svg";
import disabledtrash from "../../../../../Images/disabledtrashicon.svg";
import { useUser } from "../../../../../context/UserContext";
import PermissionSwitch from "../../../../../components/PermissionLocks/PermissionSwitch";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_SUPERADMIN,
} from "../../../../../constants/agentEnums";
import ActionModal from "../../../../../components/ActionModal/ActionModal";
import { MEMBER_ACTION_TEXT } from "../../../../../constants/casesConstants";

function MemberCard({
  memberlist,
  removeMember,
  handleUpdateMember,
  isdeleteMemberLoader,
  openDeleteModal,
  handleMemberDeleteModalopen,
  handleMemberDeleteModalclose,
}) {
  const { agent } = useUser();

  const getCreatedByName = (member) => {
    if (member?.createdBy === null || member?.createdBy === undefined) {
      return `${member?.firstName} ${member?.lastName}`;
    } else {
      return member?.createdBy?.contactName;
    }
  };

  function normalizeCountryCode(countryCode) {
    if (!countryCode) return "";
    let normalizedCode = countryCode.replace(/^\++/, "+");
    if (!normalizedCode.startsWith("+")) {
      normalizedCode = "+" + normalizedCode;
    }
    return normalizedCode;
  }

  return (
    <div style={{ overflowY: "auto" }}>
      <div>
        <table className="w-full border-collapse border border-gray-300">
          <thead className="bg-gray-100 border-b-2 border-gray-400 shadow-sm">
            <tr className="text-left">
              <th className="text-[#000] font-normal p-2">Name</th>
              <th className="text-[#000] font-normal p-2">Email</th>
              <th className="text-[#000] font-normal p-2">Contact Number</th>
              <th className="text-[#000] font-normal p-2">Created By</th>
              <th className="text-[#000] font-normal p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {memberlist.map((member, index) => (
              <tr key={index} className="even:bg-gray-100">
                <td className="p-2 align-top">
                  <div>
                    <span>{`${member?.firstName} ${member?.lastName}`}</span>
                    <p className="text-gray-500 text-sm">{member?.role}</p>
                  </div>
                </td>
                <td className="p-2 align-top">{member?.contactEmail}</td>
                <td className="p-2 align-top">
                  ({normalizeCountryCode(member?.countryCode)})-
                  {member?.contactPhone}
                </td>
                <td className="p-2 align-top">{getCreatedByName(member)}</td>
                <td className="p-2 flex gap-5 min-w-[100px] align-top">
                  <PermissionSwitch
                    shouldHaveRoles={[AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN]}
                    hasRole={agent?.role}
                    yes={
                      <div data-testid="EditButton">
                        <button
                          onClick={() => handleUpdateMember(member)}
                          className="p-1.5 text-white bg-transparent border-none cursor-pointer"
                        >
                          <img src={edit} alt="Edit" />
                        </button>
                      </div>
                    }
                    no={
                      <button
                        className="p-1.5 text-white bg-transparent border-none"
                        disabled={true}
                      >
                        <img src={disablededit} alt="Edit" />
                      </button>
                    }
                  />

                  <PermissionSwitch
                    shouldHaveRoles={[AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN]}
                    hasRole={agent?.role}
                    yes={
                      <button
                        onClick={() => handleMemberDeleteModalopen(member?._id)}
                        className="p-1.5 text-white bg-transparent border-none cursor-pointer"
                      >
                        <img src={trash} alt="Delete" />
                      </button>
                    }
                    no={
                      <button
                        className="p-1.5 text-white bg-transparent border-none"
                        disabled={true}

                      >
                        <img src={disabledtrash} alt="Delete" />
                      </button>
                    }
                  />
                </td>
                {openDeleteModal === member?._id && (
                  <ActionModal
                    title={MEMBER_ACTION_TEXT}
                    onClose={handleMemberDeleteModalclose}
                    onconfirm={() => {
                      removeMember(member?._id);
                    }}
                    isloader={isdeleteMemberLoader}
                     data-testid="delete-modal"
                  />
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MemberCard;
