import React from "react";
import Label from "../../../../components/Label/Label";
import Icon from "../../../../components/Icon/Icon";
import { playbook } from "../../../../constants/settingConstant";

export default function PlaybookSideBar({
  playbookData,
  handlePlaybookClick,
  loadMore,
  pageNum,
  totalPages,
  setIsModalOpen,
  selectedPlaybook,
  toggleLockPlaybook,
  handleUploadDocumentopen,
}) {
  return (
    <div
      className="border-r border-[#E4E4E4] bg-[#FFF]"
      style={{ height: "calc(100dvh - 74px)" }}
    >
      <div className="flex flex-col px-4 py-5">
        <div className="flex items-center justify-between pb-2 mb-2">
          <Label
            className="font-inter text-[14px] font-medium leading-[140%] text-[#727272]"
            htmlFor={"Playbook"}
            text={"Playbooks"}
          />
          <div className="flex flex-row gap-2">
            <div className="flex flex-row gap-1 items-center cursor-pointer"
                onClick={() => handleUploadDocumentopen()} 
            >
              <span className="text-[14px] text-[#727272]">Upload</span>
              <Icon
                type="UploadIcon"
                className="text-[#000]"
                data-testid="plusIcon"
              />
            </div>
            <Icon
              type="PlusIcon"
              className="cursor-pointer text-[#000]"
              onClick={() => setIsModalOpen(true)} 
              data-testid="plusIcon"
            />
          </div>
        </div>
        <div style={{ height: "calc(90dvh - 100px)", overflowY: "auto" }}>
          {playbookData?.map((playbook) => {
            return (
              <div
                key={playbook._id}
                className={`flex items-center gap-1 self-stretch pt-2 pb-2 pr-2 pl-1 rounded-[4px] cursor-pointer ${
                  selectedPlaybook?._id === playbook._id ? "bg-[#F2F2F2]" : ""
                }`}
                onClick={() => handlePlaybookClick(playbook)}
              >
                <div className="flex justify-between w-full item-center">
                  <Label
                    className="font-inter text-[16px] font-medium leading-[150%] cursor-pointer"
                    htmlFor={playbook.name}
                    text={playbook.name}
                  />
                  <div className="flex flex-row gap-2">
                    {!playbook?.isLocked ? (
                      <div></div>
                    ) : (
                      <Icon
                        type="LockIcon"
                        className={
                          "w-3 h-3 min-w-[12px] min-h-[12px] text-black-400 mt-1 ml-[3px]"
                        }
                      />
                    )}
                    <Icon
                      type="ActivityIcon"
                      className={`w-3 h-3 min-w-[12px] min-h-[12px] ${
                        playbook.isActive ? "text-green-500" : "text-gray-400"
                      } mt-1 ml-[3px]`}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          {pageNum < totalPages && (
            <button
              className="mt-2 px-4 py-2 text-blue-500 rounded cursor-pointer"
              onClick={loadMore}
            >
              Load More
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
