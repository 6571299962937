import React, { useEffect, useState } from "react";
import edit from "../../../Images/editicon.svg";
import trash from "../../../Images/trashicon.svg";
import avtar from "../../../Images/Avtar.png";
import { useUser } from "../../../context/UserContext";
import Button from "../../../components/Button/Button";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { ORG_DETAILS_ROUTE } from "../../../constants/routeEnums";
import { createSignedRequest } from "../../../services/coreService";
import Icon from "../../../components/Icon/Icon";
import { activeInactiveOrganizationDetail } from "../../../services/coreService";
import Loader from "../../../components/Loaders/Loader";
import ActionModal from "../../../components/ActionModal/ActionModal";
import { ORGANIZATION_ACTION_TEXT } from "../../../constants/casesConstants";

function OrganizationTable({ list, handleUpdateOrganization, deleteOrganization, setOrgList, isdeleteorganizationloader,openDeleteModal,handleOrganizationDeleteModalopen, handleOrganizationDeleteModalclose }) {
  // const { agent } = useAgent();
  const navigate = useNavigate();

  const [orgStatusLoader, setOrganizationStatusLoader] = useState({});


  const handleOrganizationStatus = async (organizationId, status) => {
    setOrganizationStatusLoader((prev) => ({
      ...prev,
      [organizationId]: true,
    }));

    try {
      const payload = {
        action: status,
      };

      const data = await activeInactiveOrganizationDetail(
        organizationId,
        payload
      );

      if (data.error) {
        return;
      }

      setOrgList((prev) =>
        prev.map((o) =>
          o._id === organizationId ? { ...o, isActive: !o.isActive } : o
        )
      );

    } catch (error) {
      console.error("Error Updating Playbook Status", error);
    }

    setOrganizationStatusLoader((prev) => ({
      ...prev,
      [organizationId]: false, // Set loader for the specific org._id
    }));

  };


  const fetchSignedUrl = async (documentUrl) => {
    try {
      const response = await createSignedRequest(documentUrl);

      if (response?.status === "Success") {

        return response.signed_url;
      }
      return null;
    } catch (error) {

      return null;
    }
  };

  useEffect(() => {
    const updateOrgListWithSignedUrls = async () => {
      const updatedList = await Promise.all(
        list?.map(async (org) => {

          if (org?.organizationLogoUrl) {

            const signedUrl = await fetchSignedUrl(org.organizationLogoUrl);
            return { ...org, signedUrl };
          }
          return org;
        })
      );
      setOrgList(updatedList);
    };

    updateOrgListWithSignedUrls();
  }, []);


  return (
    <div
      style={{
        overflowX: "auto",
      }}
    >
      <div>
        <table className="w-full border border-collapse border-gray-300" data-testid="org-table">
          <thead className="bg-gray-100 border-b-2 border-gray-400 shadow-sm">
            <tr className="text-left">
              <th className="text-black-700 font-normal p-2 text-center text-[14px] border-r border-gray-300 min-w-[100px]">
                Name
              </th>
              <th className="text-black-700 font-normal p-2 text-center text-[14px] border-r border-gray-300 min-w-[100px]">
                Logo
              </th>
              <th className="text-black-700 font-normal p-2 text-center text-[14px] border-r border-gray-300 min-w-[100px]">
                Tax Id
              </th>
              <th className="text-black-700 font-normal p-2 text-center text-[14px] border-r border-gray-300 min-w-[100px]">
                Contact Number
              </th>
              <th className="text-black-700 font-normal p-2 text-center text-[14px] border-r border-gray-300 min-w-[100px]">
                Country
              </th>
              <th className="text-black-700 font-normal p-2 text-center text-[14px] border-r border-gray-300 min-w-[100px]">
                Address
              </th>
              <th className="text-black-700 font-normal p-2 text-center text-[14px] border-r border-gray-300 min-w-[100px]">
                Created By
              </th>
              <th className="text-black-700 font-normal p-2 text-center text-[14px] border-r border-gray-300 min-w-[100px]">
                Created Date
              </th>
              <th className="text-black-700 font-normal p-2 text-center text-[14px] border-r border-gray-300 min-w-[100px]">
                Details
              </th>
              <th className="text-black-700 font-normal p-2 text-center text-[14px] border-r border-gray-300 min-w-[100px]">
                Status
              </th>
              <th className="text-black-700 font-normal p-2 text-center text-[14px] border-r border-gray-300 min-w-[50px]">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((org, index) => (
              <tr className="even:bg-gray-100" key={index}>
                <td className="p-2 text-center text-[14px] border-r border-gray-300">
                  <span>{org?.registeredName}</span>
                </td>
                <td className="p-2 border-r border-gray-300">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={org?.signedUrl || avtar}
                      style={{ width: "30px", height: "30px", borderRadius: '50%' }}
                    />
                  </div>
                </td>
                <td className="p-2 text-center text-[14px] border-r border-gray-300">
                  {org?.taxId}
                </td>
                <td className="p-2 text-center text-[14px] border-r border-gray-300">
                  {org?.contactPhone}
                </td>
                <td className="p-2 text-center text-[14px] border-r border-gray-300">
                  {org?.country}
                </td>
                <td className="p-2 text-center text-[14px] border-r border-gray-300">
                  {org?.address}
                </td>
                <td className="p-2 text-center text-[14px] border-r border-gray-300">
                  {org?.createdBy?.contactName}
                </td>
                <td className="p-2 text-center text-[14px] border-r border-gray-300">
                  {dayjs(org?.createdAt).format("DD-MMM-YYYY")}
                </td>
                <td className="p-2 text-center border-r border-gray-300" data-testid="ViewButton">
                  <div
                    onClick={() =>
                      navigate(
                        `${ORG_DETAILS_ROUTE.replace(
                          ":organization_id",
                          org._id
                        )}`
                      )
                    }
                    className="flex flex-row justify-center"
                  >
                    <Button
                      className="w-[90px] bg-[#000000] text-white px-1 py-1"
                      text={"View"}
                    />
                  </div>
                </td>
                <td className="p-2 text-center border-r border-gray-300">
                  <div className="flex flex-row justify-center" >
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        handleOrganizationStatus(
                          org?._id,
                          org?.isActive ? "deactivation" : "activation"
                        )
                      }
                    >
                      {orgStatusLoader[org?._id] ? (
                        <Loader />
                      ) : org?.isActive ? (
                        <Icon
                          type="ToggleOnIcon"
                          className="text-[#4CAF50] h-5 w-6"
                          data-testid="StatusToggle"
                        />
                      ) : (
                        <Icon type="ToggleOffIcon" className="h-5 w-6" data-testid="StatusToggle" />
                      )}
                    </div>
                  </div>
                </td>
                <td className="p-2 text-center border-r border-gray-300">
                  <div className="flex flex-row justify-center" >
                    <button data-testid="Delete" className="p-1.5 text-white bg-transparent border-none cursor-pointer"
                      onClick={() => handleOrganizationDeleteModalopen(org?._id)}
                    >
                      <img src={trash} alt="Delete" />
                    </button>
                  </div>
                </td>
                {openDeleteModal === org?._id && (
                  <ActionModal
                    title={ORGANIZATION_ACTION_TEXT}
                    onClose={handleOrganizationDeleteModalclose}
                    onconfirm={() => { 
                      deleteOrganization(org?._id);
                      handleOrganizationDeleteModalclose();
                    }}
                    isloader={isdeleteorganizationloader}
                  />
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OrganizationTable;
