import React, { useEffect } from "react";
import Switch2_0 from "../../../../components/SwitchV2/SwitchV2";
import PermissionSwitch from "../../../../components/PermissionLocks/PermissionSwitch.jsx";
import Button from "../../../../components/Button/Button";
import Sparkle from "../../../../Images/Sparklewhite.svg";
import Loader from "../../../../components/Loaders/Loader";
import Icon from "../../../../components/Icon/Icon.jsx";

import { decisionoptions } from "../../../../constants/casesConstants";
import {
  DISPUTE_CASE_STATUS_RESPONSE_GENERATE_LETTER,
  DISPUTE_CASE_STATUS_RESPONSE_GENERATION_COMPLETE,
} from "../../../../constants/disputeCaseStatusConstants.js";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_CONTRIBUTER,
  AGENT_ROLE_READER,
  AGENT_ROLE_SUPERADMIN,
} from "../../../../constants/agentEnums";
import { useState } from "react";
import { updateResponseData } from "../../../../services/coreService.js";
import { showNotification } from "../../../../components/Toastify/Toast.js";

const CaseResponse = ({
  organizationId,
  caseHighlights,
  remedialData,
  caseHighlightsDate,
  letterData,
  letterlist,
  letterTitle,
  getLatestUpdatedAt,
  handleGenerateResponse,
  globalCaseStatus,
  agent,
  isRegenerateLoader,
  isAIResponseGeneratedLoader,
  setIsResponseLetterEdited,
  isResponseLetterEdited,
  responseId,
}) => {

  const [isEditing, setIsEditing] = useState(false);
  const [isTitleEditing, setIsTitleEditing] = useState(false);

  const [responseTitleLoader, setResponseTitleLoader] = useState(false);
  const [letterDataTextLoader, setLetterDataTextLoader] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [editCaseResponse, setEditCaseResponse] = useState({
    letterData: letterData || " ",
    responseTitle: letterTitle || " ",
  });

  const [tempCaseResponse, setTempCaseResponse] = useState({
    letterData: letterData || " ",
    responseTitle: letterTitle || " ",
  });


  useEffect(() => {

    setEditCaseResponse({
      letterData: letterData || " ",
      responseTitle: letterTitle || " ",
    });

    setTempCaseResponse({
      letterData: letterData || " ",
      responseTitle: letterTitle || " ",
    });


  }, [letterData]);


  const handleChange = (keyName, value) => {
    setEditCaseResponse((prev) => ({
      ...prev,
      [keyName]: value,
    }));
  };


  const handleEdit = (data) => {

    if (data === "letterData") setIsEditing(true);
    else setIsTitleEditing(true);

  };

  const handleCancel = (data) => {
    if (data === "letterData") {
      setIsEditing(false);
    }
    else {
      setIsTitleEditing(false)

    };

    setEditCaseResponse({
      letterData: tempCaseResponse.letterData,
      responseTitle: tempCaseResponse.responseTitle,
    });

  };


  const handleSave = async (data) => {

    if (!editCaseResponse.letterData || !editCaseResponse.responseTitle) {
      showNotification("Field cannot be empty", "error");
      return;
    }

    if (data === "letterData") setLetterDataTextLoader(true);
    else setResponseTitleLoader(true);

    try {

      const payload = {
        caseResponse: {
          editLetterData: editCaseResponse.letterData,
          editTitle: editCaseResponse.responseTitle
        }
      }
      await updateResponseData(organizationId, responseId, payload);

      setIsEditing(false);
      setIsTitleEditing(false);

      setTempCaseResponse({
        letterData: editCaseResponse.letterData,
        responseTitle: editCaseResponse.responseTitle
      });

      if (data === "letterData") setLetterDataTextLoader(false);
      else setResponseTitleLoader(false);

      setIsResponseLetterEdited(true);
    }
    catch (error) {
      // console.log(error);
      setIsEditing(false);
      setIsTitleEditing(false);

      setEditCaseResponse({
        letterData: tempCaseResponse.letterData,
        responseTitle: tempCaseResponse.responseTitle,
      });

      if (data === "letterData") setLetterDataTextLoader(false);
      else setResponseTitleLoader(false);


    }

  };

  const handleOpenRegenerateResponse = () => {
    if (isResponseLetterEdited) {
      setIsModalOpen(true);
    }
    else {
      handleGenerateResponse();
    }
  }

  const showAiGeneratedTag = (letterlist) => {
    return letterlist[0]?.isAIResponseGenerated && !letterlist[0]?.isEdited;
  };
  
  return (
    <div class="flex flex-col space-y-4 pt-[24px] mb-3">
      <div>
        <div class="flex flex-row justify-between">
          <div className="w-full pb-[12px] px-[32px]">
            <div className="flex space-x-2 items-center w-full">
              <h6 className="text-[var(--Color-Tokens-Content-Dark-Primary, #000)] font-inter text-[16px] font-normal leading-[150%] whitespace-nowrap">
                Key Case Highlights
              </h6>
              {showAiGeneratedTag(letterlist) ?
              <div className="flex items-center gap-2">
                <Switch2_0 initialState={"false"} date={caseHighlightsDate} />
              </div> : null }
            </div>
            <ul className="list-disc pl-5 text-[#727272] font-inter text-[14px] font-normal leading-[150%] mt-4 overflow-y-auto max-h-32">
              {typeof caseHighlights === "string" ? (
                <p>{caseHighlights}</p>
              ) : (
                caseHighlights?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))
              )}
            </ul>
          </div>

          <div className="w-full pt-[8px] pb-[12px] px-[32px]">
            <div className="flex space-x-2 items-center w-full">
              <h6 className="text-[var(--Color-Tokens-Content-Dark-Primary, #000)] font-inter text-[16px] font-normal leading-[150%]">
                Remediations
              </h6>
              {showAiGeneratedTag(letterlist) ?
              <div className="flex items-center gap-2">
                <Switch2_0
                  initialState="false"
                  date={getLatestUpdatedAt(remedialData)}
                /> 
              </div> : null}
            </div>
            <ul className="list-disc pl-5 text-[#727272] font-inter text-[14px] font-normal leading-[150%] mt-4 overflow-y-auto max-h-32">
              {remedialData?.map((item, index) => (
                <li key={index}>{item.title}</li>
              ))}
            </ul>
          </div>
        </div>

        <div class="pt-[12px] pb-[24px] px-[32px]">
          <div className="flex flex-row justify-between border-t border-b border-[#E4E4E4]">
            {!isRegenerateLoader ? (
              <>
                <div class="pt-[16px] pb-[16px] text-ellipsis overflow-hidden text-black font-inter text-lg font-medium leading-[150%] ">
                  {isTitleEditing ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        value={editCaseResponse.responseTitle}
                        onChange={(e) => handleChange("responseTitle", e.target.value)}
                        className="border border-gray-300 rounded px-2 py-1 focus:outline-none"
                        placeholder="Edit title here"
                      />
                      {responseTitleLoader ? <div><Loader /></div> : <div className="flex gap-2"><span
                        onClick={() => handleSave("Title")}
                        className="cursor-pointer text-blue-500 hover:underline"
                      >
                        Save
                      </span>
                        <span
                          onClick={() => handleCancel("Title")}
                          className="cursor-pointer text-red-500 hover:underline"
                        >
                          Cancel
                        </span>
                      </div>
                      }
                    </div>
                  ) : (
                    <div className="flex items-center gap-2">
                      <div
                        className="text-ellipsis overflow-hidden text-black font-inter text-lg font-medium leading-[150%] cursor-pointer"

                      >
                        {editCaseResponse.responseTitle || ""}
                      </div>
                      <Icon
                        type="EditIcon"
                        className="h-4 w-4 cursor-pointer"
                        onClick={() => handleEdit("Title")}
                        id="EditIcon-Title"
                      />
                    </div>

                  )}
                </div>
                {globalCaseStatus && (
                  <PermissionSwitch
                    shouldHaveRoles={[
                      AGENT_ROLE_SUPERADMIN,
                      AGENT_ROLE_ADMIN,
                      AGENT_ROLE_CONTRIBUTER,
                    ]}
                    hasRole={agent?.role}
                    yes={
                      isRegenerateLoader ? (
                        <div className="flex flex-col justify-center">
                          <Loader />
                        </div>
                      ) : (
                        <Button
                          icon={Sparkle}
                          lefticon={true}
                          disabled={
                            globalCaseStatus ===
                            DISPUTE_CASE_STATUS_RESPONSE_GENERATE_LETTER ||
                            isAIResponseGeneratedLoader
                          }
                          className="w-[200px] bg-[#4a6cfd] h-[38px] px-2 py-2 text-[26px] text-white-700 text-white self-center"
                          text={"Regenerate Response"}
                          onClick={() => handleOpenRegenerateResponse()}

                        />
                      )
                    }
                    no={
                      <Button
                        icon={Sparkle}
                        lefticon={true}
                        disabled={true}
                        className="w-[200px] bg-[#4a6cfd] h-[38px] px-2 py-2 text-[26px] text-white-700 text-white self-center"
                        text={"Regenerate Response"}
                        onClick={null}
                      />
                    }
                  />
                )}{" "}
              </>
            ) : null}
          </div>{" "}
          {isRegenerateLoader || isAIResponseGeneratedLoader ||
            globalCaseStatus === DISPUTE_CASE_STATUS_RESPONSE_GENERATE_LETTER
            ? (
              <div className="flex flex-col items-center justify-center h-[50vh] text-center">
                <p className="mb-5">Generating Response. Please Wait !</p>
                <Loader />
              </div>
            ) : !letterData || letterData?.length === 0 ? (
              <div className="flex items-center justify-center h-[50vh] text-center text-gray-500">
                <p>No response data available at the moment.</p>
              </div>
            ) : (
              <div>
                <div className="flex justify-between pt-[16px] pb-[16px] px-[16px] py-[16px]">
                  {showAiGeneratedTag(letterlist) ? 
                  <div className="flex items-center gap-2">
                    <Switch2_0
                      initialState={!isResponseLetterEdited}
                      date={getLatestUpdatedAt(letterlist)}
                    />
                  </div> : null }
                  {isEditing ? (

                    <div className="flex gap-2">
                      {
                        letterDataTextLoader ? <div><Loader /> </div> : <div className="flex gap-2">
                          <span
                            onClick={() => handleSave("letterData")}
                            className="cursor-pointer text-blue-500 hover:underline"
                          >
                            Save
                          </span>
                          <span
                            onClick={() => handleCancel("letterData")}
                            className="cursor-pointer text-red-500 hover:underline"
                          >
                            Cancel
                          </span>
                        </div>
                      }
                    </div>
                  ) : (
                    <Icon
                      type="EditIcon"
                      className="h-4 w-4 cursor-pointer"
                      onClick={() => handleEdit("letterData")}
                      id="EditIcon-LetterData"
                    />
                  )}
                </div>
                <div className="text-[#727272] text-xl font-normal leading-[27px] whitespace-pre-line">
                  {isEditing ? (
                    <textarea
                      type="text"
                      value={editCaseResponse.letterData}
                      onChange={(e) => handleChange("letterData", e.target.value)}
                      className="w-full h-[100vh] border border-gray-300 rounded px-2 py-2 focus:outline-none"

                    />
                  ) : (
                    <div className="text-[#727272] text-xl font-normal leading-[27px] whitespace-pre-line">
                      <p> {editCaseResponse.letterData} </p>
                    </div>
                  )}
                </div>
              </div>

            )}
        </div>
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-[90%] max-w-md">
              <p className="text-center text-lg font-medium mb-4">
                Are you sure you want to regenerate response?
              </p>
              <div className="flex justify-center space-around gap-4">
                <Button
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 text-sm bg-gray-300 hover:bg-gray-400 rounded w-[120px]"
                  text="Cancel"
                />

                <Button
                  onClick={() => {
                    handleGenerateResponse();
                    setIsModalOpen(false);
                  }}
                  className="px-4 py-2 text-sm bg-blue-600 text-white hover:bg-blue-700 rounded w-[120px]"
                  text="Yes"
                />

              </div>
            </div>
          </div>
        )}

      </div>

    </div>
  );
};

export default CaseResponse;
