import Overlay from "../../../../components/Overlay/Overlay";
import Button from "../../../../components/Button/Button";
import TextAreaForm from "../../../../components/FormTextArea/FormTextArea";
import {
  EscalateCase,
  validityoptions,
} from "../../../../constants/casesConstants";
import SelectDropdown from "../../../../components/DropDown/Dropdown";
import collapse from "../../../../Images/Collapse.svg";
import Loader from "../../../../components/Loaders/Loader";
import megaphone from "../../../../Images/Megaphone.svg";

function EscalateDisputeCase({
  isescalatemodal,
  escalateloading,
  handleEsclateModalClose,
  escalatejustification,
  selectedEscalateCase,
  setSelectedEscalateCase,
  setEscalateJustification,
  handleEsclateCase
}) {
 return (
    <Overlay
            isOpen={isescalatemodal}
            onClose={handleEsclateModalClose}
            title="Esclate Case"
            className="custom-class"
            position="center"
          >
            <div className="px-8">
              <p className="text-[16px] text-black mb-2">Department</p>
              <SelectDropdown
                options={EscalateCase}
                value={selectedEscalateCase}
                onChange={setSelectedEscalateCase}
                collapseIcon={collapse}
                placeholder="Select an option..."
                role="combobox"
              />
            </div>
            <div className="w-full max-w-md mx-auto pt-5">
              <TextAreaForm
                value={escalatejustification}
                label="Justification"
                placeholder={
                  "e.g. Based on the evidence provided it is clear that a mistake was made regarding the customers credit status and their credit account should be wiped"
                }
                isValid={true}
                handleChange={(e) => setEscalateJustification(e.target.value)}
                id="editEscalateJustification"
              />
            </div>
            <div className="flex flex-row justify-between gap-6 px-8 mt-8">
              <Button
                className="w-[50%] bg-[#F2F2F2] px-4 py-2 "
                text={"Cancel"}
                textcolor="#000000"
                onClick={handleEsclateModalClose}
                dataTestId="Cancel"
              />
              {escalateloading ? (
                <div className="w-[50%] py-1" data-testid="loader">
                  <Loader />
                </div>
              ) : (
                <Button
                  icon={megaphone}
                  className="w-[50%] bg-[#AF4B4B] px-4 py-1 text-white"
                  text={"Escalate"}
                  onClick={() => handleEsclateCase()}
                  dataTestId="Escalate"
                />
              )}
            </div>
          </Overlay>
 )
}

export default EscalateDisputeCase;