import React, { useState } from "react";
import InputForm from "../../../../components/InputForm/InputForm";
import Button from "../../../../components/Button/Button";
import { showNotification } from "../../../../components/Toastify/Toast";
import { useOrganization } from "../../../../context/OrganizationContext";
import { uploadPlaybookJsonFile } from "../../../../services/uploaderApi";
import Loader from "../../../../components/Loaders/Loader";
import sample from "../../../../constants/sample.json";
import Icon from "../../../../components/Icon/Icon";

function UploadDocument({ handleUploadDocumentclose, setPlayBookData }) {
  const [file, setFile] = useState(null);
  const [documentname, setDocumentname] = useState("");
  const { organization } = useOrganization();
  const [uploadfileLoader, setUploadFileLoader] = useState(false);
  const [errors, setErrors] = useState({});

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "application/json") {
      setFile(selectedFile);
    } else {
      setFile(null);
      showNotification("Please select json file", "error");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === "application/json") {
      setFile(droppedFile);
    } else {
      setFile(null);
      showNotification("Please select json file", "error");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleValidation = () => {
    let validationErrors = {};
    let isValid = true;

    if (!documentname.trim()) {
      validationErrors["documentname"] = "Please enter playbook name";
      isValid = false;
    }
    if (!file) {
      validationErrors["file"] = "Please upload a JSON file";
      isValid = false;
    }

    setErrors(validationErrors);
    return isValid;
  };

  const uploadPlaybookFile = async () => {
    const isValid = handleValidation({ documentname, file });
    if (!isValid) return;
  
    setUploadFileLoader(true);
    try {
      let res;
      res = await uploadPlaybookJsonFile(organization?._id, documentname, file);
      setUploadFileLoader(false);
      if (res?.data?.status === "Success") {
        const { newPlaybook } = res?.data?.data;
        setPlayBookData((prev) => [...prev, newPlaybook]);
        handleUploadDocumentclose();
      }
    } catch (error) {
      showNotification(error, "error");
      setUploadFileLoader(false);
    }
  };
  

  const downloadSampleFile = () => {
    const blob = new Blob([JSON.stringify(sample, null, 2)], {
      type: "application/json",
    });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = "sample.json";
    link.click();
    URL.revokeObjectURL(url);
  };

  const showError = (keyName) => {
    return errors?.[keyName] ? (
      <p className="text-red-500 text-[14px] pl-2 pt-2">{errors[keyName]}</p>
    ) : null;
  };
  return (
    <div className="flex">
      <div className="w-full py-2 px-8">
        <span className="text-[16px] text-gray-500">Playbook Name</span>
        <InputForm
          value={documentname}
          handleChange={(e) => setDocumentname(e.target.value)}
          placeholder="Enter Name Playbook Name"
          isValid={true}
        />
           {showError("documentname")}

        <div
          className="border-dashed border-2 border-gray-300 p-12 text-center cursor-pointer mt-4"
          onClick={() => document.getElementById("file-input").click()}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {file ? (
            <div className="text-center">
              <p className="text-[18px] text-gray-700">✓✓ File Uploaded</p>
            </div>
          ) : (
            <div>
              <div className="flex flex-row justify-center">
                <Icon
                  type="CirclePlusIcon"
                  className="h-8 w-8 self-center ml-1"
                />
              </div>
              <p className="text-base text-[28px] text-black-600 font-bold">
                Select a JSON file to upload
              </p>
              <p className="text-base text-gray-600">
                or drag and drop it here
              </p>
            </div>
          )}
          <input
            type="file"
            id="file-input"
            style={{ display: "none" }}
            onChange={handleFileSelect}
            accept="application/json"
          />
        </div>
        {showError("file")}
        <div
          className="flex flex-row justify-end gap-1 mt-1"
          onClick={downloadSampleFile}
        >
          <div className="self-center cursor-pointer">
            <Icon type="DownloadIcon" className="h-4 w-4 ml-1" />
          </div>
          <span className="text-black text-[14px] cursor-pointer">
            Download Sample JSON
          </span>
        </div>
        <div className="mt-5 flex flex-row justify-end">
          {uploadfileLoader ? (
            <Loader />
          ) : (
            <Button
              className="w-[35%] bg-[#000000] text-white px-4 py-2 "
              text={"Upload"}
              onClick={uploadPlaybookFile}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadDocument;
