import React from 'react'
import Icon from '../Icon/Icon'
import Button from '../Button/Button'
import Loader from '../Loaders/Loader';

function ActionModal({ title, onClose, onconfirm, isloader }) {
  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-[90%] max-w-md h-auto">
          <div className="flex justify-center mb-4">
            <Icon type="DeleteIcon" className="h-10 w-10" />
          </div>
          <p className="text-center text-[22px] font-medium mb-4">
            Are You Sure?
          </p>
          <p className="text-center text-[16px] text-gray-600 font-medium mb-4">
            {title}
          </p>
          <div className="flex justify-center gap-4">
            <Button
              onClick={onClose}
              className="px-4 py-2 text-sm bg-gray-300 hover:bg-gray-400 rounded w-[120px]"
              text="Cancel"
              data-testid="cancel-button" 
            />
            {isloader ? (
              <div className='w-[35%]'>
                <Loader />
              </div>
            ) : (
              <Button
                onClick={onconfirm}
                className="px-4 py-2 text-sm bg-red-600 text-white hover:bg-red-700 rounded w-[120px]"
                text="Yes,Delete it!"
                data-testid="confirm-delete-button" 
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActionModal;
