import Label from "../../../../components/Label/Label";
import FormTextArea from "../../../../components/FormTextArea/FormTextArea";
import Icon from "../../../../components/Icon/Icon";
import { useState, useEffect } from "react";
import Button from "../../../../components/Button/Button";
import EditPlaybookContent from "./EditPlaybookContent";
import Loader from "../../../../components/Loaders/Loader";

const PLAYBOOK_CONTENT_LIMIT = 2000;

const PlaybookModalContent = ({
  playbookComplaints,
  handleCreatePlaybook,
  setIsModalOpen,
  createplaybookloading
}) => {
  const [expandedCreateComplaints, setExpandedCreateComplaints] = useState([]);
  const [playbookName, setPlaybookName] = useState("");
  const [apologyCriteria, setApologyCriteria] = useState("");
  const [complainCriteria, setComplainCriteria] = useState("");
  const [identifySubclaims, setIdentifySubclaims] = useState("");
  const [mergeSubclaims, setMergeSubclaims] = useState("");
  const [identifyCategories, setIdentifyCategories] = useState("");
  const [reviewSubclaims, setReviewSubclaims] = useState("");
  const [globalText, setGlobalText] = useState("");




  const [wordCount, setWordCount] = useState({
    apology: 0,
    complaint: 0,
    identifysubclaim: 0,
    mergesubclaim: 0,
    identifycategory: 0,
    reviewsubclaim: 0,
    globalText:0
  });

  const handleCreate = async () => {
    let complaintTypes = getComplaintTypesFromInput(inputData);
    await handleCreatePlaybook({
      playbookName,
      complainCriteria,
      apologyCriteria,
      identifySubclaims,
      mergeSubclaims,
      identifyCategories,
      reviewSubclaims,
      complaintTypes,
      globalText
    });
  };

  const getComplaintTypesFromInput = (inputData) => {
    let array = [];
    playbookComplaints.forEach((item) => {
      array.push({
        typeOfComplaint: item._id,
        criteriaToIdentifySubClaims: inputData["indentify"][item._id]
          ? inputData["indentify"][item._id].trim()
          : "",
        criteriaToAssessEvidence: inputData["assessEvidence"][item._id]
          ? inputData["assessEvidence"][item._id].trim()
          : "",
        remedialActionsGuidance: inputData["remedial"][item._id]
          ? inputData["remedial"][item._id].trim()
          : "",
        monetaryValueGuidance: inputData["monetary"][item._id]
          ? inputData["monetary"][item._id].trim()
          : "",
      });
    });
    if (array && array.length > 0) {
      array = array.filter(
        (item) =>
          item.typeOfComplaint !== "" &&
          item.criteriaToIdentifySubClaims !== "" &&
          item.criteriaToAssessEvidence !== "" &&
          item.remedialActionsGuidance !== "" &&
          item.monetaryValueGuidance !== ""
      );
    }
    return array;
  };

  const [inputData, setInputData] = useState({
    indentify: {}, // For Criteria to Identify Subclaims
    assessEvidence: {}, // For Criteria to Assess Evidence
    remedial: {}, // For Remedial Actions Guidance
    monetary: {}, // For Monetary Value Guidance
  });

  const toggleCreateComplaintDetails = (id) => {
    setExpandedCreateComplaints((prev) =>
      prev.includes(id)
        ? prev.filter((complaintId) => complaintId !== id)
        : [...prev, id]
    );
  };

  const handleInputChange = (field, id, value) => {
    setInputData((prevData) => ({
      ...prevData,
      [field]: {
        ...prevData[field],
        [id]: value,
      },
    }));
  };

  const handleApologyChange = (e) => {
    const inputText = e.target.value;

    const words = inputText.trim().split(/\s+/);
    let update = { ...wordCount };
    if (words.length <= 2000) {
      setApologyCriteria(inputText);
      update["apology"] = inputText.trim() ? words.length : 0;
      setWordCount(update);
    }
  };

  const handleIdentifySubclaimsChange = (e) => {
    const inputText = e.target.value;

    const words = inputText.trim().split(/\s+/);
    let update = { ...wordCount };
    if (words.length <= 2000) {
      setIdentifySubclaims(inputText);
      update["identifysubclaim"] = inputText.trim() ? words.length : 0;
      setWordCount(update);
    }
  };

  const handlemergeSubclaimsChange = (e) => {
    const inputText = e.target.value;

    const words = inputText.trim().split(/\s+/);
    let update = { ...wordCount };
    if (words.length <= 2000) {
      setMergeSubclaims(inputText);
      update["mergesubclaim"] = inputText.trim() ? words.length : 0;
      setWordCount(update);
    }
  };

  const handleglobalTextChange = (e) => {
    const inputText = e.target.value;

    const words = inputText.trim().split(/\s+/);
    let update = { ...wordCount };
    if (words.length <= 2500) {
      setGlobalText(inputText);
      update["globalText"] = inputText.trim() ? words.length : 0;
      setWordCount(update);
    }
  };

  const handleidentifyCategoriesChange = (e) => {
    const inputText = e.target.value;

    const words = inputText.trim().split(/\s+/);
    let update = { ...wordCount };
    if (words.length <= 2000) {
      setIdentifyCategories(inputText);
      update["identifycategory"] = inputText.trim() ? words.length : 0;
      setWordCount(update);
    }
  };

  const handlereviewSubclaimsChange = (e) => {
    const inputText = e.target.value;

    const words = inputText.trim().split(/\s+/);
    let update = { ...wordCount };
    if (words.length <= 2000) {
      setReviewSubclaims(inputText);
      update["reviewsubclaim"] = inputText.trim() ? words.length : 0;
      setWordCount(update);
    }
  };

  const handleComplainChange = (e) => {
    const inputText = e.target.value;
    const words = inputText.trim().split(/\s+/);
    let update = { ...wordCount };
    if (words.length <= 500) {
      setComplainCriteria(inputText);
      update["complaint"] = inputText.trim() ? words.length : 0;
      setWordCount(update);
    }
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
        <div className="bg-white rounded-lg shadow-lg w-[600px] h-[600px] flex flex-col">
          {/* Header */}
          <div className="sticky top-0 z-10 px-6 py-4 bg-white border-b border-gray-200">
            <h2 className="text-lg font-bold text-black">
              {"Create a Playbook"}
            </h2>
          </div>

          {/* Body */}
          <div className="flex-1 overflow-y-auto">
            <div className="px-6 py-4">
              <Label
                className="block text-[16px] font-medium text-[#727272]"
                htmlFor="playbookName"
                text="Name"
              />
              <input
                id="playbookName"
                className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md"
                placeholder="Enter Playbook Name"
                value={playbookName}
                onChange={(e) => setPlaybookName(e.target.value)}
              />
            </div>
            <div className="px-6 pt-1">
              <div className="flex flex-col space-y-2">
                <EditPlaybookContent
                  title={"Apology Criteria"}
                  id={"apologyLetterGuidance1"}
                  placeholder={"Enter Apology Criteria (Limit: 2000 words)"}
                  value={apologyCriteria}
                  handleChange={handleApologyChange}
                  wordLimit={"2000"}
                />
                <EditPlaybookContent
                  title={"Complaint Classifying Criteria"}
                  id={"complaintClassifyingCriteria1"}
                  placeholder={
                    "Enter Complaint Classifying Criteria (Limit: 500 words)"
                  }
                  value={complainCriteria}
                  handleChange={handleComplainChange}
                  wordLimit={"500"}
                />
                <EditPlaybookContent
                  title={"Identify Subclaim"}
                  id={"Identify Subclaim"}
                  placeholder={"Enter Identify Subclaim (Limit: 2000 words)"}
                  value={identifySubclaims}
                  handleChange={handleIdentifySubclaimsChange}
                  wordLimit={"2000"}
                />
                <EditPlaybookContent
                  title={"Merge Subclaim"}
                  id={"mergeSubclaim"}
                  placeholder={"Enter Merge Subclaim (Limit: 2000 words)"}
                  value={mergeSubclaims}
                  handleChange={handlemergeSubclaimsChange}
                  wordLimit={"2000"}
                />
                <EditPlaybookContent
                  title={"Identify Categories"}
                  id={"identifyCategories"}
                  placeholder={"Enter Identify Categories (Limit: 2000 words)"}
                  value={identifyCategories}
                  handleChange={handleidentifyCategoriesChange}
                  wordLimit={"2000"}
                />
                <EditPlaybookContent
                  title={"Review Subclaim"}
                  id={"reviewSubclaims"}
                  placeholder={"Enter Review Subclaim (Limit: 2000 words)"}
                  value={reviewSubclaims}
                  handleChange={handlereviewSubclaimsChange}
                  wordLimit={"2000"}
                />
                   <EditPlaybookContent
                  title={"Global Text"}
                  id={"globalText"}
                  placeholder={
                    "Enter Global Text (Limit: 2500 words)"
                  }
                  value={globalText}
                  handleChange={handleglobalTextChange}
                  wordLimit={"2500"}
                />
              </div>
            </div>
            <div className="px-6 py-4">
              <Label
                className="font-inter text-[16px] font-medium text-[#727272] mb-4"
                htmlFor={"complaintTypes"}
                text={"Complaint Types"}
              />
              {playbookComplaints?.map((complaint) => (
                <div key={complaint._id} className="flex flex-col mt-3">
                  <div
                    className={`flex items-center justify-between p-3 bg-[#F9F9F9] border border-[#E4E4E4] rounded-[4px] cursor-pointer ${expandedCreateComplaints.includes(complaint._id)
                      ? "bg-[#F2F2F2]"
                      : ""
                      }`}
                    onClick={() => toggleCreateComplaintDetails(complaint._id)}
                  >
                    <span className="font-inter text-[14px] font-medium text-[#000]">
                      {complaint.typeOfComplaint}
                    </span>
                    {expandedCreateComplaints.includes(complaint._id) ? (
                      <Icon type="ArrowUpIcon" className="text-[#727272]" />
                    ) : (
                      <Icon type="ArrowDownIcon" className="text-[#727272]" />
                    )}
                  </div>
                  {expandedCreateComplaints.includes(complaint._id) && (
                    <div className="p-3 mb-3 rounded-[4px] border border-[#E4E4E4] text-[#000] text-[14px] leading-[150%] space-y-2">
                      {/* Criteria to Identify Subclaims */}
                      <div>
                        <EditPlaybookContent
                          title={"Criteria to Identify Subclaims:"}
                          id={`identify-${complaint._id}`}
                          value={inputData.indentify[complaint._id] || ""}
                          placeholder={
                            "Enter Criteria to Identify Subclaims (Limit: 500 words)"
                          }
                          wordLimit={500}
                          handleChange={(e) =>
                            handleInputChange(
                              "indentify",
                              complaint._id,
                              e.target.value
                            )
                          }
                        />
                      </div>

                      {/* Criteria to Assess Evidence */}
                      <div>
                        <EditPlaybookContent
                          title={"Criteria to Assess Evidence:"}
                          id={`assess-${complaint._id}`}
                          value={inputData.assessEvidence[complaint._id] || ""}
                          placeholder={
                            "Enter Criteria to Assess Evidence (Limit: 500 words)"
                          }
                          wordLimit={500}
                          handleChange={(e) =>
                            handleInputChange(
                              "assessEvidence",
                              complaint._id,
                              e.target.value
                            )
                          }
                        />
                      </div>

                      {/* Remedial Actions Guidance */}
                      <div>
                        <EditPlaybookContent
                          title={"Remedial Actions Guidance:"}
                          id={`remedial-${complaint._id}`}
                          value={inputData.remedial[complaint._id] || ""}
                          placeholder={
                            "Enter Remedial Actions Guidance (Limit: 500 words)"
                          }
                          wordLimit={500}
                          handleChange={(e) =>
                            handleInputChange(
                              "remedial",
                              complaint._id,
                              e.target.value
                            )
                          }
                        />
                      </div>

                      {/* Monetary Value Guidance */}
                      <div>
                        <EditPlaybookContent
                          title={"Monetary Value Guidance:"}
                          value={inputData.monetary[complaint._id] || ""}
                          placeholder={
                            "Enter Monetary Value Guidance (Limit: 500 words)"
                          }
                          wordLimit={500}
                          handleChange={(e) =>
                            handleInputChange(
                              "monetary",
                              complaint._id,
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* Footer */}
          <div className="sticky bottom-0 z-10 flex justify-between px-6 py-2 bg-white border-t border-gray-200">
            <Button
              className="w-full bg-[#F2F2F2] text-black px-4 py-2 ml-1"
              text={"Close"}
              onClick={() => setIsModalOpen(false)}
            />
            {
              createplaybookloading ?
              <div className="w-[100%]">
              <Loader /> 
              </div>
              :
            
            <Button
              className="w-full bg-[#000000] text-black px-4 py-2 mr-1"
              textcolor={"white"}
              text={"Create"}
              onClick={handleCreate}
            />
}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlaybookModalContent;
