import storageService from "./storageService";
import axios from "axios";
import { showNotification } from "../components/Toastify/Toast";
import { attemptLogout } from "./loginUtility";
import { ENV_LOCAL } from "../constants/enum";

const API_URL =
  process.env.REACT_APP_STAGE === ENV_LOCAL
    ? process.env.REACT_APP_CORE_API
    : `/api`;

export const attemptUploader = async (organizationId, caseId, tags, file) => {
  let headers = {};

  try {
    let authUser = storageService.getItem("token")
      ? storageService.getItem("token")
      : null;

    if (authUser) {
      let token = authUser;
      if (token && token !== "") {
        // @ts-ignore
        headers.Authorization = `Bearer ${token}`;
      } else {
        throw "No Token Found!";
      }
    }
    const formData = new FormData();
    formData.append("documents", file);

    const url = `${API_URL}/v1/disputecases/organization/${organizationId}/case/${caseId}/upload-document?evidence_category=${tags}`;

    let response = await axios.post(url, formData, { headers });
    return response;
  } catch (error) {
    if (error.status === 403) {
      return attemptLogout();
    }
    showNotification(error?.response?.data?.data?.message, "error");
    console.error("Error uploading file:", error);
  }
};

export const orgAdminUploader = async (organizationId, file) => {
  let headers = {};

  try {
    let authUser = storageService.getItem("token")
      ? storageService.getItem("token")
      : null;

    if (authUser) {
      let token = authUser;
      if (token && token !== "") {
        // @ts-ignore
        headers.Authorization = `Bearer ${token}`;
      } else {
        throw "No Token Found!";
      }
    }
    const formData = new FormData();
    formData.append("file", file);

    const url = `${API_URL}/v1/admin/organization/${organizationId}/upload-logo`;

    let response = await axios.post(url, formData, { headers });
    showNotification("Uploaded Successfully", "success");
    return response.data;
  } catch (error) {
    if (error.status === 401) {
      return attemptLogout();
    }
    showNotification(error?.response?.data?.data?.message, "error");
    console.error("Error uploading file:", error);
  }
};

export const uploadProfileImage = async (organizationId, agentId, file) => {
  let headers = {};

  try {
    let authUser = storageService.getItem("token")
      ? storageService.getItem("token")
      : null;

    if (authUser) {
      let token = authUser;
      if (token && token !== "") {
        // @ts-ignore
        headers.Authorization = `Bearer ${token}`;
      } else {
        throw "No Token Found!";
      }
    }
    const formData = new FormData();
    formData.append("file", file);

    const url = `${API_URL}/v1/profile/organization/${organizationId}/agent/${agentId}/upload-profileImage`;

    let response = await axios.post(url, formData, { headers });
    showNotification("Uploaded Successfully", "success");
    return response;
  } catch (error) {
    if (error.status === 401) {
      return attemptLogout();
    }
    showNotification(error?.response?.data?.data?.message, "error");
    console.error("Error uploading file:", error);
  }
};


export const uploadPlaybookJsonFile = async (organizationId, playbookname, file) => {
  let headers = {};

  try {
    let authUser = storageService.getItem("token")
      ? storageService.getItem("token")
      : null;

    if (authUser) {
      let token = authUser;
      if (token && token !== "") {
        headers.Authorization = `Bearer ${token}`;
      } else {
        throw "No Token Found!";
      }
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", playbookname);

    const url = `${API_URL}/v1/playbook/organization/${organizationId}/upload-json`;

    let response = await axios.post(url, formData, { headers });
    showNotification("Uploaded Successfully", "success");
    return response;
  } catch (error) {
    if (error.status === 401) {
      return attemptLogout();
    }
    showNotification(error?.response?.data?.data?.message, "error");
    console.error("Error uploading file:", error);
  }
};

