import Overlay from "../../../../components/Overlay/Overlay";
import Button from "../../../../components/Button/Button";
import TextAreaForm from "../../../../components/FormTextArea/FormTextArea";
import SelectDropdown from "../../../../components/DropDown/Dropdown";
import collapse from "../../../../Images/Collapse.svg";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_CONTRIBUTER,
  AGENT_ROLE_READER,
  AGENT_ROLE_SUPERADMIN,
} from "../../../../constants/agentEnums";
import {
  validityoptions,
} from "../../../../constants/casesConstants";
import PermissionSwitch from "../../../../components/PermissionLocks/PermissionSwitch";

function EditValidity ({
    agent,
    editmonetaryDetails,
    iseditvaliditymodal,
    handlEditValidityModalClose,
    selectvalidity,
    setSelectedvalidity,
    validityChangeReason,
    setValidityChangeReason,
    isEditModalSaveLoader,
    handleClaimValidate
}) {
 return (
    <Overlay
            isOpen={iseditvaliditymodal}
            onClose={handlEditValidityModalClose}
            title="Edit Validity"
            className="custom-class"
            position="center"
          >
            <div className="px-8">
              <SelectDropdown
                options={validityoptions}
                value={selectvalidity}
                onChange={(val) => setSelectedvalidity(val)}
                collapseIcon={collapse}
                placeholder="Select an option..."
                role="combobox"
              />
            </div>
            <div>
              <div className="w-full max-w-md mx-auto pt-5">
                <TextAreaForm
                  defaultFontForTitle={"15"}
                  value={validityChangeReason}
                  handleChange={(e) => setValidityChangeReason(e.target.value)}
                  label="Justification"
                  placeholder={
                    "e.g. Based on the evidence provided it is clear that a mistake was made regarding the customers credit status and their credit account should be wiped"
                  }
                  isValid={true}
                  id="editvalidityreason"
                />
              </div>
            </div>
            <div className="flex flex-row justify-between gap-6 px-8 mt-8">
              <Button
                className="w-full bg-[#F2F2F2] px-4 py-2 "
                text={"Cancel"}
                textcolor="#000000"
                onClick={handlEditValidityModalClose}
                dataTestId={"Cancel"}
              />
              <PermissionSwitch
                shouldHaveRoles={[
                  AGENT_ROLE_SUPERADMIN,
                  AGENT_ROLE_ADMIN,
                  AGENT_ROLE_CONTRIBUTER,
                ]}
                hasRole={agent?.role}
                yes={
                  <Button
                    disabled={isEditModalSaveLoader}
                    className="w-full bg-[#000000] text-white px-4 py-2 "
                    text={isEditModalSaveLoader ? "Saving..." : "Save"}
                    onClick={() =>
                      handleClaimValidate(
                        editmonetaryDetails?._id,
                        selectvalidity,
                        validityChangeReason
                      )
                    }
                    dataTestId="Save"
                  />
                }
                no={
                  <Button
                    className="w-full bg-[#000000] text-white px-4 py-2 "
                    text={"Save"}
                    disabled={true}
                    dataTestId="Save"
                  />
                }
              />
            </div>
          </Overlay>
 )
}

export default EditValidity;