import Overlay from "../../../../components/Overlay/Overlay";
import Button from "../../../../components/Button/Button";
import InputForm from "../../../../components/InputForm/InputForm";
import TextAreaForm from "../../../../components/FormTextArea/FormTextArea";
import Loader from "../../../../components/Loaders/Loader";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_CONTRIBUTER,
  AGENT_ROLE_READER,
  AGENT_ROLE_SUPERADMIN,
} from "../../../../constants/agentEnums";
import PermissionSwitch from "../../../../components/PermissionLocks/PermissionSwitch";

function EditRemedialAction({
  agent,
  iscreateremedialloader,
  remedialclaimid,
  isremedialmodal,
  handlRemedialModalClose,
  remedialactionDetails,
  remedialtitle,
  remedialjustification,
  setRemedialJustification,
  isremoveremedialloder,
  removeRemedialaction,
  createRemedialaction,
  setRemedialTitle
}) {
  return (
    <Overlay
      isOpen={isremedialmodal}
      onClose={handlRemedialModalClose}
      title={
        remedialactionDetails
          ? "Edit Remedial Action"
          : "Create Remedial Action"
      }
      className="custom-class"
      position="center"
      data-testid="remedialModal"
    >
      <div>
        <InputForm
          label="Title"
          value={remedialtitle}
          handleChange={(e) => setRemedialTitle(e.target.value)}
          placeholder={"e.g. Wipe customers credit account"}
          isValid={true}
          id={"Title"}
        />
      </div>
      <div className="w-full max-w-md mx-auto pt-5">
        <TextAreaForm
          label="Justification"
          value={remedialjustification}
          handleChange={(e) => setRemedialJustification(e.target.value)}
          placeholder={
            "e.g. Based on the evidence provided it is clear that a mistake was made regarding the customers credit status and their credit account should be wiped"
          }
          isValid={true}
          id="Justification"
        />
      </div>
      <div className="flex flex-row justify-between gap-6 px-8 mt-8">
        <PermissionSwitch
          shouldHaveRoles={[
            AGENT_ROLE_SUPERADMIN,
            AGENT_ROLE_ADMIN,
            AGENT_ROLE_CONTRIBUTER,
          ]}
          hasRole={agent?.role}
          yes={
            <>
              {remedialactionDetails && (
                <>
                  {isremoveremedialloder ? (
                    <div className="w-[100%]">
                      <Loader />
                    </div>
                  ) : (
                    <Button
                      className="w-full bg-[#ffffff] border border-red-600 px-4 py-2"
                      text="Delete"
                      textcolor="red"
                      onClick={() =>
                        removeRemedialaction(
                          remedialactionDetails?._id,
                          remedialactionDetails?.caseClaimId
                        )
                      }
                  dataTestId="Delete"
                    />
                  )}
                </>
              )}
            </>
          }
          no={
            <>
              {remedialactionDetails && (
                <Button
                  className="w-full bg-[#ffffff] border border-red-600 px-4 py-2"
                  text={"Delete"}
                  textcolor="red"
                  onClick={() => {}}
                  dataTestId="Delete"
                />
              )}
            </>
          }
        />
        <Button
          onClick={handlRemedialModalClose}
          className="w-full bg-[#F2F2F2] px-4 py-2 "
          text={"Cancel"}
          textcolor="#000000"
          dataTestId="closeRemedialButton"
        />
        <PermissionSwitch
          shouldHaveRoles={[
            AGENT_ROLE_SUPERADMIN,
            AGENT_ROLE_ADMIN,
            AGENT_ROLE_CONTRIBUTER,
          ]}
          hasRole={agent?.role}
          yes={
            <>
              {iscreateremedialloader ? (
                <div className="w-[100%]">
                  <Loader />
                </div>
              ) : (
                <Button
                  className="w-full bg-[#000000] text-white px-4 py-2 "
                  text={remedialactionDetails ? "Save" : "Create"}
                  onClick={() =>
                    createRemedialaction(
                      remedialactionDetails
                        ? remedialactionDetails?._id
                        : remedialclaimid
                    )
                  }
                  dataTestId="Create"
                />
              )}
            </>
          }
          no={
            <Button
              className="w-full bg-[#000000] text-white px-4 py-2 "
              text={remedialactionDetails ? "Save" : "Create"}
              disabled={true}
              dataTestId="Create"

            />
          }
        />
      </div>
    </Overlay>
  );
}

export default EditRemedialAction;
