import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import {
  getPlaybookCompaints,
  createPlaybook,
  updatePlaybook,
  updatePlaybookStatus,
  updatePlaybookComplaint,
  deletePlaybook,
  copyClonePlaybook,
  lockPlaybook,
  downloadPlaybookJsonFile,
} from "../../../../services/coreService";
import { showNotification } from "../../../../components/Toastify/Toast";
import PermissionSegment from "../../../../components/PermissionLocks/PermissionSegment";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_SUPERADMIN,
} from "../../../../constants/agentEnums";
import { useUser } from "../../../../context/UserContext";
import AddComplaintTypeModal from "./AddComplaintTypeModal";

import {
  PLAYBOOK_COMPLAINT_TYPE_CONTRACT_DISPUTES,
  PLAYBOOK_COMPLAINT_TYPE_BILLING,
  PLAYBOOK_COMPLAINT_TYPE_CUSTOMER_SERVICE,
  PLAYBOOK_COMPLAINT_TYPE_SERVICE_AVAILABILITY,
  PLAYBOOK_COMPLAINT_TYPE_UNKNOWN,
  PLAYBOOK_COMPLAINT_TYPE_PACKAGE,
} from "../../../../constants/playbookComplaintsTypeConstants";
import EditComplaintModal from "./EditComplaintModal";
import FormTextArea from "../../../../components/FormTextArea/FormTextArea";
import playbookComplaints from "../../../../constants/playbookComplaintsData.json";
import PlaybookModalContent from "./PlaybookModalContent";
import PlaybookSideBar from "./PlaybookSideBar";
import PlaybookDetails from "./PlaybookDetails";
import UploadDocument from "./UploadDocument";
import Overlay from "../../../../components/Overlay/Overlay";

const Playbook = ({
  playbookData,
  organizationId,
  setPlayBookData,
  setPageNum,
  pageNum,
  totalPages,
}) => {
  const [isLabelClicked, setIsLabelClicked] = useState(false);
  const [expandedComplaints, setExpandedComplaints] = useState([]);
  const [playbookComplaintsData, setplaybookComplaintsData] = useState([]);

  const [selectedPlaybook, setSelectedPlaybook] = useState("");
  const [initialPlaybook, setInitialPlaybook] = useState("");

  const [selectedPlaybookStatus, setSelectedPlaybookStatus] = useState("");

  const [playbookStatusLoader, setPlaybookStatusLoader] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploadDocumentOpen, setIsUploadDocumentOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const [isAddComplaintModalOpen, setIsAddComplaintModalOpen] = useState(false);

  const [editApologyPlaybookLoading, setEditApologyPlaybookLoading] =
    useState(false);
  const [editIdentifySubclaimPlaybookLoading, setEditIdentifySubclaimPlaybookLoading] =
    useState(false);
  const [editMergeSubclaimPlaybookLoading, setEditMergeSubclaimPlaybookLoading] =
    useState(false);
  const [editReviewSubclaimPlaybookLoading, setEditReviewSubclaimPlaybookLoading] =
    useState(false);
  const [editglobalTextLoading, setEditGlobalTextLoading] =
    useState(false);
  const [editIdentifyCategoriesPlaybookLoading, setEditIdentifyCategoriesPlaybookLoading] =
    useState(false);
  const [editCompaintPlaybookLoading, setEditCompaintPlaybookLoading] =
    useState(false);

  const [editplaybooktitleLoading, seteditplaybooktitleLoading] =
    useState(false);

  const [isEditableApology, setIsEditableApology] = useState({});
  const [isEditableComplaint, setIsEditableComplaint] = useState({});
  const [isEditableIdentifySubclaim, setisEditableIdentifySubclaim] = useState({});
  const [isEditablemergeSubclaim, setIsEditablemergeSubclaim] = useState({});
  const [isEditablereviewSubclaim, setIsEditablereviewSubclaim] = useState({});
  const [isEditableglobaltext, setIsEditableGlobalText] = useState({});

  const [isEditableIdentifyCategories, setIsEditableIdentifyCategories] = useState({});



  const [isLoading, setIsLoading] = useState(false);
  const [playbooktitle, setPlaybookTitle] = useState("")
  const [islockplaybookloader, setIsLockPlayBookLoader] =
    useState(false);
  const [lockplaybook, setLockPlayBook] =
    useState("");
  const [removeplaybookloader, setRemovePlaybookLoader] =
    useState(false);
    const [createplaybookloading, setCreatePlaybookLoading] =
    useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(null);

  const handleDeletePlaybookModalOpen = (id) => {
    setOpenDeleteModal(id)
  }

  const handleDeletePlaybookModalClose = () => {
    setOpenDeleteModal(null)
  }


  const handleUploadDocumentopen = () => {
    setIsUploadDocumentOpen(true)
  }

  const handleUploadDocumentclose = () => {
    setIsUploadDocumentOpen(false)
  }
  const filteredComplaintTypes = [
    PLAYBOOK_COMPLAINT_TYPE_BILLING,
    PLAYBOOK_COMPLAINT_TYPE_CONTRACT_DISPUTES,
    PLAYBOOK_COMPLAINT_TYPE_CUSTOMER_SERVICE,
    PLAYBOOK_COMPLAINT_TYPE_PACKAGE,
    PLAYBOOK_COMPLAINT_TYPE_SERVICE_AVAILABILITY,
    PLAYBOOK_COMPLAINT_TYPE_UNKNOWN,
  ].filter(
    (type) =>
      !playbookComplaintsData?.some(
        (complaint) => complaint?.typeOfComplaint === type
      )
  );

  const { agent } = useUser();

  const loadMore = () => {
    const nextPage = pageNum + 1;
    setPageNum(nextPage);
  };

  const toggleEditableApology = (itemId) => {
    setIsEditableApology((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const toggleEditableComplaint = (itemId) => {
    setIsEditableComplaint((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const toggleEditableIdentifySubclaim = (itemId) => {
    setisEditableIdentifySubclaim((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const toggleEditableIdentifyCategories = (itemId) => {
    setIsEditableIdentifyCategories((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const toggleEditableMergeSubclaims = (itemId) => {
    setIsEditablemergeSubclaim((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const toggleEditableReviewSubclaims = (itemId) => {
    setIsEditablereviewSubclaim((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const toggleEditableGlobalText = (itemId) => {
    setIsEditableGlobalText((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const handleOpenEditModal = (complaint) => {
    setSelectedComplaint(complaint);
    setIsEditModalOpen(true);
  };

  const handleOpenAddComplaintModal = () => {
    if (filteredComplaintTypes?.length < 1) {
      showNotification("Complaint types have already been filled", "error");
      return;
    }
    setIsAddComplaintModalOpen(true);
  };

  useEffect(() => {
    if (playbookData?.length > 0) {
      if (isEmpty(selectedPlaybook)) {
        const firstPlaybook = playbookData[0];
        setSelectedPlaybook(firstPlaybook);
        setInitialPlaybook(firstPlaybook);

        const status = firstPlaybook?.isActive ? "Active" : "Inactive";
        setSelectedPlaybookStatus(status);

        setIsLabelClicked(true);
        fetchPlaybook(firstPlaybook._id);
      }
    }
  }, [playbookData]);

  useEffect(() => {
    if (selectedPlaybook?.name) {
      setPlaybookTitle(selectedPlaybook.name);
    }
  }, [selectedPlaybook]);

  const handleClickCopyClone = async (organizationId, id) => {
    try {
      setIsLoading(true)
      const res = await copyClonePlaybook(organizationId, id);
      if (res?.status === "Success") {
        setIsLoading(false)
        const { playbook } = res.data;
        setPlayBookData((prev) => [...prev, playbook]);
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }

  const handlePlaybookStatus = async (playbookId, status) => {
    setPlaybookStatusLoader(true);
    try {
      const statusBody = {
        action: status,
      };

      const data = await updatePlaybookStatus(
        organizationId,
        playbookId,
        statusBody
      );

      if (data.error) {
        return;
      }

      setSelectedPlaybook((prev) => ({
        ...prev,
        isActive: !prev.isActive,
      }));

      setInitialPlaybook((prev) => ({
        ...prev,
        isActive: !prev.isActive
      }))

      setPlayBookData((prevData) =>
        prevData.map((playbook) => {
          if (playbook._id === playbookId) {
            return { ...playbook, isActive: !playbook.isActive };
          } else {
            return {
              ...playbook,
              isActive:
                playbook.isActive && playbookId === playbook._id
                  ? !playbook.isActive
                  : false,
            };
          }
        })
      );
    } catch (error) {
      console.error("Error Updating Playbook Status", error);
    }

    setPlaybookStatusLoader(false);
  };

  const handleCreatePlaybook = async (input) => {
    console.log(input)
    if (!input.playbookName.trim()) {
      showNotification("Please Enter playbook name", "error");
      return;
    }
    if (!input.apologyCriteria.trim()) {
      showNotification("Please Enter apology criteria", "error");
      return;
    }
    if (!input.complainCriteria.trim()) {
      showNotification("Please Enter complain criteria", "error");
      return;
    }
    if (!input.identifySubclaims.trim()) {
      showNotification("Please Enter Identify Subclaims", "error");
      return;
    }
    if (!input.mergeSubclaims.trim()) {
      showNotification("Please Enter Merge Subclaims", "error");
      return;
    }
    if (!input.identifyCategories.trim()) {
      showNotification("Please Enter Identify Categories", "error");
      return;
    }
    if (!input.reviewSubclaims.trim()) {
      showNotification("Please Enter Review Subclaims", "error");
      return;
    }
    if (!input.globalText.trim()) {
      showNotification("Please Enter Global Text", "error");
      return;
    }
    if (input.complaintTypes.length === 0) {
      showNotification("Please add at least one complain type", "error");
      return;
    }
    setCreatePlaybookLoading(true)
    try {
      const playbook = {
        playbookName: input.playbookName,
        complaintClassifyingCriteria: input.complainCriteria,
        apologyLetterGuidance: input.apologyCriteria,
        identifySubclaims: input.identifySubclaims,
        mergeSubclaims: input.mergeSubclaims,
        identifyCategories: input.identifyCategories,
        reviewSubclaims: input.reviewSubclaims,
        complaintTypes: input.complaintTypes,
        globalText: input.globalText
      };
      const res = await createPlaybook(organizationId, playbook);

      if (res?.status === "Success") {
        const newPlaybook = res.data.playbook;
        setPlayBookData((prev) => [...prev, newPlaybook]);
        setIsModalOpen(false);
        setCreatePlaybookLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPlaybook = async (playbookId) => {
    try {
      const res = await getPlaybookCompaints(playbookId, organizationId);

      if (res?.status === "Success") {
        const { playbookComplaints } = res.data;

        setplaybookComplaintsData(playbookComplaints);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePlaybookClick = (data) => {
    setIsLabelClicked(true);
    setSelectedPlaybook(data);
    setInitialPlaybook(data);
    const status = data?.isActive ? "Active" : "Inactive";
    const lockstatus = data?.isLocked ? "lock" : "unlock";
    setLockPlayBook(lockstatus)
    setSelectedPlaybookStatus(status);
    fetchPlaybook(data._id);
  };


  const toggleComplaintDetails = (complaintId) => {
    setExpandedComplaints((prev) =>
      prev.includes(complaintId)
        ? prev.filter((id) => id !== complaintId)
        : [...prev, complaintId]
    );
  };



  const handleUpdatePlaybook = async (id, itemId) => {
    const playbookFieldMap = {
      apologyLetterGuidance: {
        key: "apologyLetterGuidance",
        errorMessage: "Apology Letter Guidance cannot be empty",
        toggleEditable: toggleEditableApology,
        setLoadingState: setEditApologyPlaybookLoading,
      },
      complaintClassifyingCriteria: {
        key: "complaintClassifyingCriteria",
        errorMessage: "Complaint Classifying Criteria cannot be empty",
        toggleEditable: toggleEditableComplaint,
        setLoadingState: setEditCompaintPlaybookLoading,
      },
      identifySubclaims: {
        key: "identifySubclaims",
        errorMessage: "Identify Subclaims Guidance cannot be empty",
        toggleEditable: toggleEditableIdentifySubclaim,
        setLoadingState: setEditIdentifySubclaimPlaybookLoading,
      },
      mergeSubclaims: {
        key: "mergeSubclaims",
        errorMessage: "Merge Subclaims cannot be empty",
        toggleEditable: toggleEditableMergeSubclaims,
        setLoadingState: setEditMergeSubclaimPlaybookLoading,
      },
      identifyCategories: {
        key: "identifyCategories",
        errorMessage: "Identify Categories cannot be empty",
        toggleEditable: toggleEditableIdentifyCategories,
        setLoadingState: setEditIdentifyCategoriesPlaybookLoading,
      },
      reviewSubclaims: {
        key: "reviewSubclaims",
        errorMessage: "Review Subclaims cannot be empty",
        toggleEditable: toggleEditableReviewSubclaims,
        setLoadingState: setEditReviewSubclaimPlaybookLoading,
      },
      globalText: {
        key: "globalText",
        errorMessage: "Global Text cannot be empty",
        toggleEditable: toggleEditableGlobalText,
        setLoadingState: setEditGlobalTextLoading,
      },
      playbooktitlechange: {
        key: null, // No validation needed
        errorMessage: null,
        toggleEditable: null,
        setLoadingState: seteditplaybooktitleLoading,
      },
    };

    // Process logic
    const selectedField = playbookFieldMap[id];

    if (selectedField) {
      if (selectedField.errorMessage && !selectedPlaybook?.[selectedField.key]?.trim()) {
        showNotification(selectedField.errorMessage, "error");
        return;
      }

      selectedField.toggleEditable?.(itemId);
      selectedField.setLoadingState?.(true);
    }

    try {
      const payload = {
        playbook: {
          name: playbooktitle,
          complaintClassifyingCriteria:
            selectedPlaybook.complaintClassifyingCriteria,
          apologyLetterGuidance: selectedPlaybook.apologyLetterGuidance,
          identifySubclaims: selectedPlaybook.identifySubclaims,
          mergeSubclaims: selectedPlaybook.mergeSubclaims,
          identifyCategories: selectedPlaybook.identifyCategories,
          reviewSubclaims: selectedPlaybook.reviewSubclaims,
          globalText: selectedPlaybook.globalText,

        },
      };

      const response = await updatePlaybook(
        organizationId,
        selectedPlaybook._id,
        payload
      );
      if (response?.status === "Success") {
        const status = response?.data?.playbook?.isActive
          ? "Active"
          : "Inactive";
        setSelectedPlaybookStatus(status);

        setPlayBookData((prevData) =>
          prevData.map((playbook) =>
            playbook._id === response?.data?.playbook._id
              ? { ...playbook, ...response?.data?.playbook }
              : playbook
          )
        );
        setSelectedPlaybook((prevPlaybook) => ({
          ...prevPlaybook,
          ...response.data.playbook,
        }));

        setInitialPlaybook((prevPlaybook) => ({
          ...prevPlaybook,
          ...response.data.playbook
        }))
      }
    } catch (error) {
      console.error(error);
    }
    if (id === "apologyLetterGuidance") {
      setEditApologyPlaybookLoading(false);
    } else if (id === "playbooktitlechange") {
      seteditplaybooktitleLoading(false);
    } else if (id === "identifySubclaims") {
      setEditIdentifySubclaimPlaybookLoading(false);
    } else if (id === "mergeSubclaims") {
      setEditMergeSubclaimPlaybookLoading(false);
    } else if (id === "identifyCategories") {
      setEditIdentifyCategoriesPlaybookLoading(false);
    } else if (id === "reviewSubclaims") {
      setEditReviewSubclaimPlaybookLoading(false);
    } else if (id === "globalText") {
      setEditGlobalTextLoading(false);
    } else if (id === "complaintClassifyingCriteria") {
      setEditCompaintPlaybookLoading(false);
    }
  };
  const handleRemovingPlaybook = async (id) => {
    setRemovePlaybookLoader(true);
    try {
      const response = await deletePlaybook(organizationId, id);
      setRemovePlaybookLoader(false);
      if (response?.status === "Success") {
        setPlayBookData((prevData) =>
          prevData.filter((playbook) => playbook._id !== id)
        );
        setSelectedPlaybook({});
        setInitialPlaybook({});
        handleDeletePlaybookModalClose()
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateComplaint = async () => {
    setIsLoading(true);
    try {
      const payload = {
        complaintTypePlaybook: {
          typeOfComplaint: selectedComplaint.typeOfComplaint,
          criteriaToIdentifySubClaims:
            selectedComplaint.criteriaToIdentifySubClaims,
          criteriaToAssessEvidence: selectedComplaint.criteriaToAssessEvidence,
          remedialActionsGuidance: selectedComplaint.remedialActionsGuidance,
          monetaryValueGuidance: selectedComplaint.monetaryValueGuidance,
        },
      };

      await updatePlaybookComplaint(
        organizationId,
        selectedComplaint?._id,
        payload
      );
      fetchPlaybook(selectedPlaybook._id);

      setIsEditModalOpen(false);
    } catch (error) {
      console.error(error);
      showNotification("Failed to update complaint", "error");
    }
    setIsLoading(false);
  };


  const handleLockPlaybook = async (playbookId, status) => {

    setIsLockPlayBookLoader(true);

    try {
      const payload = {
        action: status,
      };

      const data = await lockPlaybook(organizationId, playbookId, payload);
      if (data.error) {
        return;
      }

      setSelectedPlaybook((prev) => ({
        ...prev,
        isLocked: !prev.isLocked,
      }));

      setInitialPlaybook((prev) => (
        {
          ...prev,
          isLocked: !prev.isLocked
        }
      ))

      setPlayBookData((prevData) =>
        prevData.map((playbook) => {
          if (playbook._id === playbookId) {
            return { ...playbook, isLocked: !playbook.isLocked };
          } else {
            return playbook;
          }
        })
      );
      setIsLockPlayBookLoader(false);

    } catch (error) {
      console.error(error);
      setIsLockPlayBookLoader(false);
    }
  };

  const downloadplaybookUrl = async (id) => {
    try {
      const response = await downloadPlaybookJsonFile(organizationId, id);

      if (response.status === "Success") {
        const jsonData = JSON.stringify(response.data, null, 2);
        const blob = new Blob([jsonData], { type: "application/json" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = selectedPlaybook?.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);
        showNotification("Download JSON file successfully", "success")
      } else {
        showNotification("Error fetching signed URL", "error");
      }
    } catch (error) {
      showNotification("Error downloading playbook", "error");
    }
  };


  return (
    <PermissionSegment
      shouldHaveRoles={[AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN]}
      hasRole={agent?.role}
    >
      <div className="w-[100%]">
        <div className="grid grid-cols-[26.27%_calc(100%-26.27%)]">
          {/* Left Sidebar */}
          <PlaybookSideBar
            playbookData={playbookData}
            pageNum={pageNum}
            loadMore={loadMore}
            totalPages={totalPages}
            handlePlaybookClick={handlePlaybookClick}
            setIsModalOpen={setIsModalOpen}
            selectedPlaybook={selectedPlaybook}
            handleUploadDocumentopen={handleUploadDocumentopen}
          />
          {/* Right Content */}
          <div>
            {isLabelClicked && (
              <PlaybookDetails
                selectedPlaybook={selectedPlaybook}
                setSelectedPlaybook={setSelectedPlaybook}
                initialPlaybook={initialPlaybook}
                selectedPlaybookStatus={selectedPlaybookStatus}
                handlePlaybookStatus={handlePlaybookStatus}
                playbookStatusLoader={playbookStatusLoader}
                editApologyPlaybookLoading={editApologyPlaybookLoading}
                editIdentifySubclaimPlaybookLoading={editIdentifySubclaimPlaybookLoading}
                editMergeSubclaimPlaybookLoading={editMergeSubclaimPlaybookLoading}
                editReviewSubclaimPlaybookLoading={editReviewSubclaimPlaybookLoading}
                editglobalTextLoading={editglobalTextLoading}
                editIdentifyCategoriesPlaybookLoading={editIdentifyCategoriesPlaybookLoading}
                isEditableApology={isEditableApology}
                isEditableIdentifySubclaim={isEditableIdentifySubclaim}
                isEditablemergeSubclaim={isEditablemergeSubclaim}
                isEditablereviewSubclaim={isEditablereviewSubclaim}
                isEditableIdentifyCategories={isEditableIdentifyCategories}
                toggleEditableApology={toggleEditableApology}
                handleOpenAddComplaintModal={handleOpenAddComplaintModal}
                handleOpenEditModal={handleOpenEditModal}
                handleUpdatePlaybook={handleUpdatePlaybook}
                editCompaintPlaybookLoading={editCompaintPlaybookLoading}
                toggleEditableComplaint={toggleEditableComplaint}
                toggleEditableIdentifySubclaim={toggleEditableIdentifySubclaim}
                toggleEditableIdentifyCategories={toggleEditableIdentifyCategories}
                toggleEditableMergeSubclaims={toggleEditableMergeSubclaims}
                toggleEditableReviewSubclaims={toggleEditableReviewSubclaims}
                isEditableComplaint={isEditableComplaint}
                expandedComplaints={expandedComplaints}
                playbookComplaintsData={playbookComplaintsData}
                isEditableglobaltext={isEditableglobaltext}
                toggleEditableGlobalText={toggleEditableGlobalText}
                toggleComplaintDetails={toggleComplaintDetails}
                handleRemovingPlaybook={handleRemovingPlaybook}
                handleClickCopyClone={handleClickCopyClone}
                isLoading={isLoading}
                setPlaybookTitle={setPlaybookTitle}
                playbooktitle={playbooktitle}
                editplaybooktitleLoading={editplaybooktitleLoading}
                lockplaybook={lockplaybook}
                islockplaybookloader={islockplaybookloader}
                handleLockPlaybook={handleLockPlaybook}
                removeplaybookloader={removeplaybookloader}
                openDeleteModal={openDeleteModal}
                handleDeletePlaybookModalOpen={handleDeletePlaybookModalOpen}
                handleDeletePlaybookModalClose={handleDeletePlaybookModalClose}
                downloadplaybookUrl={downloadplaybookUrl}
              />
            )}
          </div>

          {/*Create Playbook Modal*/}
          {isModalOpen && (
            <PlaybookModalContent
              playbookComplaints={playbookComplaints}
              handleCreatePlaybook={handleCreatePlaybook}
              setIsModalOpen={setIsModalOpen}
              createplaybookloading={createplaybookloading}
            />
          )}

          <Overlay
            isOpen={isUploadDocumentOpen}
            onClose={handleUploadDocumentclose}
            title={"Upload File"}
            className="custom-class"
            position="center"
          >
            <UploadDocument
              handleUploadDocumentclose={handleUploadDocumentclose}
              setPlayBookData={setPlayBookData}
            />
          </Overlay>
        </div>

        {isEditModalOpen && (
          <EditComplaintModal
            selectedComplaint={selectedComplaint}
            setIsEditModalOpen={setIsEditModalOpen}
            handleUpdateComplaint={handleUpdateComplaint}
            organizationId={organizationId}
            selectedPlaybook={selectedPlaybook}
            fetchPlaybook={fetchPlaybook}
          />
        )}

        {isAddComplaintModalOpen && (
          <AddComplaintTypeModal
            organizationId={organizationId}
            playbookComplaintsData={playbookComplaintsData}
            selectedPlaybook={selectedPlaybook}
            setIsAddComplaintModalOpen={setIsAddComplaintModalOpen}
            setplaybookComplaintsData={setplaybookComplaintsData}
          />
        )}
      </div>
    </PermissionSegment>
  );
};

export default Playbook;
