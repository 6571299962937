
export const APOLOGYLETTERGUIDANCE_MAX_LIMIT = 2000;
export const COMPLAINTCLASSIFYINGCRITERIA_MAX_LIMIT = 500;
export const COMPLAINTTYPESINPUT_MAX_LIMIT = 500;
export const IDENTITY_SUBCLAIM_MAX_LIMIT = 500;
export const MERGE_SUBCLAIM_MAX_LIMIT = 500;
export const REVIEW_SUBCLAIM_MAX_LIMIT = 500;
export const IDENTITY_CATEGORIES_MAX_LIMIT = 500;
export const GLOBAL_TEXT_MAX_LIMIT = 2500;




